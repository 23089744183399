const maritalStatusChoices = [
    {
      name: '\0',
      value: '',
    },
    {
      name: 'Married',
      value: 'M',
    },
    {
      name: 'Single',
      value: 'S',
    },
    {
      name: 'Divorced',
      value: 'D',
    },
    {
      name: 'Widowed',
      value: 'W',
    },
    {
      name: 'Separated',
      value: 'E',
    },
    {
      name: 'Never Married',
      value: 'N',
    },
  ];

export default maritalStatusChoices