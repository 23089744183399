export interface IHasSearchText {
    SearchText: string
}

/** 
 * Given a string input, converts to lower case, removes all non-alpha-numeric 
 * characters except spaces, and trims outside whitespace. As a bonus, removes 
 * "Dr" if it appears at the start of the string. */
export const toSearchString = function(input: string): string {
    const str = input || '';
    return str.toLowerCase().replace(',', ' ').replace(/[^a-z0-9 ]/,'').replace(/^dr/, '').trim();
}

/**
 * Filters the provided array based on the specified search string, 
 * applying multi-word matching where each word in the search string 
 * is matched independently (but all must be found).
 * @param options - The array of options to be filtered.
 * @param search  - The search string. It is expected that the string has already 
 *                  been converted to search format (see toSearchString above)
 * @returns  - The filtered array.
 */
export const filterOptions = function<T extends IHasSearchText>(options: T[], search:string): T[] {
    const filtered = options.filter((o) => { 
        const words = search.split(' ').filter(i => i);
        let isMatch = true;
        for (var i = 0; i < words.length; i += 1) {
            isMatch = isMatch && o.SearchText.includes(words[i]);
        }
        return isMatch;
    });
    return filtered;
}
