import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { NOVALUE } from '../../constants/textConstants';
import styleVars from '../../styleVars';
import {
  parseAndGroupFormData,
  FormValue,
} from '../../helpers/parseAndGroupFormData';

import './CondensedReview.scss';

export const CondensedReview = ({ formValues, completedPage }: any) => {
  const {
    yourInfo,
    referralInfo,
    authorizations,
    stakeholders,
    patient,
    other,
  } = useMemo(() => parseAndGroupFormData(formValues), [formValues]);

  // We only want to calculate these values once
  const [
    yourInfoShown,
    referralInfoShown,
    authorizationsShown,
    stakeholdersShown,
    patientShown,
    otherShown,
  ] = useMemo(() => {
    const showSections = [];
    showSections.push(showSection(yourInfo));
    showSections.push(showSection(referralInfo));
    showSections.push(showSection(authorizations));
    showSections.push(showSection(stakeholders));
    showSections.push(showSection(patient));
    showSections.push(showSection(other));
    return showSections;
  }, [yourInfo, referralInfo, authorizations, stakeholders, patient, other]);

  return (
    <div className="condensed-review">
      <div
        className={
          completedPage ? 'completed-page flex-container' : 'flex-container'
        }
      >
        <div className="flex-half">
          <>
            {yourInfoShown && (
              <Typography variant="h6" className="section-header">
                Your info
              </Typography>
            )}
            {yourInfo?.map(({ name, value }: FormValue) => {
              return (
                !!value &&
                value !== NOVALUE && (
                  <>
                    <span className="flex-label">{`${name}:`}</span>
                    <span className="flex-value">{value}</span>
                  </>
                )
              );
            })}
          </>
          <>
            {referralInfoShown && (
              <Typography variant="h6" className="section-header">
                Referral information
              </Typography>
            )}
            {referralInfo?.map(({ name, value }: FormValue) => {
              return (
                !!value &&
                value !== NOVALUE && (
                  <>
                    <span className="flex-label">{`${name}:`}</span>
                    <span className="flex-value">{value}</span>
                  </>
                )
              );
            })}
          </>
        </div>
        <div className="flex-half">
          <>
            {(stakeholdersShown ||
              patientShown ||
              otherShown ||
              authorizationsShown) && (
              <Typography
                variant="h6"
                style={{
                  color: styleVars.colorGreen,
                  fontSize: styleVars.fontSizeXxlarge,
                  fontWeight: 500,
                }}
                className="section-header"
              >
                Additional info
              </Typography>
            )}
          </>
          <>
            {authorizationsShown && (
              <Typography
                variant="body1"
                style={{
                  color: styleVars.colorGreen,
                  fontSize: styleVars.fontSizeNormal,
                  fontWeight: 500,
                }}
                className="subsection"
              >
                AUTHORIZATIONS
              </Typography>
            )}
            {authorizations?.map(({ name, value }: FormValue) => {
              return (
                !!value &&
                value !== NOVALUE && (
                  <>
                    <span className="flex-label">{`${name}:`}</span>
                    <span className="flex-value">{value}</span>
                  </>
                )
              );
            })}
            {stakeholdersShown && (
              <Typography
                variant="body1"
                style={{
                  color: styleVars.colorGreen,
                  fontSize: styleVars.fontSizeNormal,
                  fontWeight: 500,
                  marginTop: '17px',
                }}
                className="subsection"
              >
                STAKEHOLDERS
              </Typography>
            )}

            {stakeholders?.map(({ name, value }: FormValue) => {
              return (
                !!value &&
                value !== NOVALUE && (
                  <>
                    <span className="flex-label">{`${name}:`}</span>
                    <span className="flex-value">{value}</span>
                  </>
                )
              );
            })}
            {patient?.some(
              ({ value }: FormValue) => !!value && value !== NOVALUE
            ) && (
              <Typography
                variant="body1"
                style={{
                  color: styleVars.colorGreen,
                  fontSize: styleVars.fontSizeNormal,
                  fontWeight: 500,
                  marginTop: '17px',
                }}
                className="subsection"
              >
                PATIENT
              </Typography>
            )}

            {patient?.map(({ name, value }: FormValue) => {
              return (
                !!value &&
                value !== NOVALUE && (
                  <>
                    <span className="flex-label">{`${name}:`}</span>
                    <span className="flex-value">{value}</span>
                  </>
                )
              );
            })}
            {otherShown && (
              <Typography
                variant="body1"
                style={{
                  color: styleVars.colorGreen,
                  fontSize: styleVars.fontSizeNormal,
                  fontWeight: 500,
                  marginTop: '17px',
                }}
              >
                OTHER
              </Typography>
            )}

            {other?.map(({ name, value }: FormValue) => {
              return (
                !!value &&
                value !== NOVALUE && (
                  <>
                    <span className="flex-label">{`${name}:`}</span>
                    <span className="flex-value">{value}</span>
                  </>
                )
              );
            })}
          </>
        </div>
      </div>
    </div>
  );
};

const showSection = (section: any[]) =>
  section?.some(({ value }: FormValue) => !!value && value !== NOVALUE);
