import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import styleVars from '../../styleVars';

import Icon from 'react-icons-kit';
import { ic_close as icClose } from 'react-icons-kit/md';

const useDialogStyles = makeStyles((theme) => ({
  dialogRoot: {
    '& .MuiDialog-paper': {
      maxWidth: '672px',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: styleVars.colorCardBackground,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    cursor: 'pointer',
  },
  dialogContentContainer: {
    overflow: 'visible',
  },
}));

interface DialogContainerProps {
  dialogContent: (x: DialogRenderProps) => JSX.Element;
  dialogTrigger?: (x: DialogRenderProps) => JSX.Element;
  dismissible?: boolean;
  hideCloseButton?: boolean;
  initialState?: boolean;
  title?: string;
  icon?: React.ReactElement;
  isOpen?: boolean;
  classes?: string;
  onDialogClose?: (toggleDialog: () => void) => void;
}

interface DialogRenderProps {
  toggleDialog: () => void;
}

const DialogContainer = ({
  dialogContent,
  dialogTrigger,
  dismissible = false,
  hideCloseButton = false,
  initialState = false,
  title,
  icon,
  isOpen,
  classes,
  onDialogClose,
}: DialogContainerProps) => {
  const { dialogRoot, dialogContentContainer, closeButton } = useDialogStyles();
  const [dialogOpen, setDialogOpen] = useState(initialState);

  const toggleDialog = () => setDialogOpen((isOpen: boolean) => !isOpen);

  useEffect(() => {
    if (isOpen !== undefined) {
      setDialogOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!dialogOpen && typeof onDialogClose === 'function') {
      onDialogClose(toggleDialog);
    }
  }, [dialogOpen, onDialogClose]);

  return (
    <Fragment>
      {dialogTrigger && dialogTrigger({ toggleDialog })}
      {dialogOpen && (<Dialog
        disableEnforceFocus
        disableEscapeKeyDown={!dismissible}
        scroll="body"
        open={dialogOpen}
        onClose={toggleDialog}
        PaperProps={{
          elevation: 3,
        }}
        className={`${dialogRoot} ${classes}`}
      >
        {icon ? icon : null}
        {title && title.trim().length ? (
          <DialogTitle>{title}</DialogTitle>
        ) : null}
        {!hideCloseButton ? (
          <Icon icon={icClose} className={closeButton} onClick={toggleDialog} />
        ) : null}
        <DialogContent className={dialogContentContainer}>
          {dialogContent({ toggleDialog })}
        </DialogContent>
      </Dialog>)}
    </Fragment>
  );
};

export default DialogContainer;
