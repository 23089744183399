/**
 * Allows sending arbitrary error notification to the server 
 * with a message that will be sent to a teams channel.
 */
export async function sendErrorNotification(messsage: string) {
    const notifyErrorUrl = `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/notify_error?source=webform&message=${messsage}`;
    try {
        await fetch(notifyErrorUrl, { method: "POST" });
    }
    catch(error) {
        console.error(error);
    }
}
