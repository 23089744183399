import { RequiredInfoFormSchema } from "../types/ReferralFormSchema"
export const checkFastTrack= (formValues: RequiredInfoFormSchema) => {
      return (formValues?.patientFirstName &&
      formValues?.patientLastName &&
      formValues?.patientBirthday &&
      formValues?.patientPhoneNumber &&
      formValues?.patientSex  &&
      formValues?.patientPrimaryLanguage &&
      formValues?.claimNumber &&
      formValues?.stateOfJury &&
      formValues?.caseType &&
      formValues?.specialty &&
      formValues?.dateOfInjury  &&
      (!!formValues?.checkBoxForPhysicianInfo ?
        (formValues?.physicianFirstName &&
        formValues?.physicianLastName &&
        (formValues?.physicianEmail ||
        formValues?.physicianPhoneNumber))
        : 
        (formValues?.treatingPhysician)) &&
      formValues?.medicalDiagnosis &&
      formValues?.bodyPart &&
      formValues.surgical &&
      formValues?.jobTitle &&
      (!!formValues?.checkBoxForEmployer ?
      (formValues?.employerCompany &&
        formValues?.employerContactName &&
        formValues?.employerAddress &&
        formValues?.employerCity &&
        formValues?.employerState  &&
        formValues?.employerZipCode &&
        (formValues?.employerEmail ||
        formValues?.employerPhoneNumber) 
      ) : (formValues?.employerSelected)
      )
      )

}