import React, { useState, useEffect, useCallback } from 'react';
import MagicSearchInput from '../generic/MagicSearchInput';
import { Autocomplete } from 'formik-material-ui-lab';
import { Field, FormikTouched, FormikErrors } from 'formik';
import debounce from '../../../helpers/debounce';
import { ReferralFormSchema } from '../../../types/ReferralFormSchema';
import { ic_search as icSearch } from 'react-icons-kit/md';
import Icon from 'react-icons-kit';

type Diagnosis = {
  diagnosisCode: string;
  diagnosisDescription: string;
};

export const ICDSelect = (props: {
  name: string;
  disabled?: boolean;
  touched: FormikTouched<{ [field: string]: any }>;
  errors: { [field: string]: any };
  setTouched: (
    fields: { [field: string]: any },
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
}) => {
  const [icdCodes, setIcdCodes] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [searchComplete, setSearchComplete] = useState(false);

  const fetchBodyParts = (value: string) => {
    setSearchComplete(false);
    setIcdCodes([]);
    if (value?.length >= 3) {
      // clean up the value to strip out invalid chars (BNOTE2-10183)
      const cleanVal = value.replace(/[^A-Za-z0-9\. -]+/, '')
      fetch(
        `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/ICD_search/${cleanVal}`,
        {
          headers: { accept: 'application/json' },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(JSON.stringify(response.status));
          }
          return response.json();
        })
        .then((data) => {
          setIcdCodes(
            data
              ? data?.map(
                  (d: {
                    diagnosisCode: string;
                    diagnosisDescription: string;
                  }) => {
                    return {
                      label: `${d.diagnosisCode} - ${d.diagnosisDescription}`,
                      id: d.diagnosisCode,
                      combined: `${d.diagnosisCode} - ${d.diagnosisDescription}`,
                      // diagnosisCode: d.diagnosisCode,
                      // diagnosisDescription: d.diagnosisDescription,
                      ...d,
                    };
                  }
                )
              : []
          );
          setSearchComplete(true);
        })
        .catch((error) => console.log('Error:', error));
    }
  };

  const debouncedFetch = useCallback(debounce(fetchBodyParts, 500), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    debouncedFetch(inputValue);
  }, [inputValue, debouncedFetch]);

  return (
    <Field
      name={props.name}
      component={Autocomplete}
      id="icd-select-search"
      disabled={props.disabled}
      options={icdCodes}
      getOptionLabel={(option: { label: string }) => option.label}
      onChange={(e: MouseEvent, value: Diagnosis[]) => {
        props.setFieldValue(props.name, value);
      }}
      filterOptions={(options: { value: Diagnosis; label: string }) => options}
      onBlur={() => {
        const touchedFields = { ...props.touched };
        touchedFields[props.name] = true;
        props.setTouched(touchedFields);
      }}
      noOptionsText={
        icdCodes.length === 0 && !!searchComplete
          ? 'No results'
          : inputValue?.length >= 3
          ? 'Searching...'
          : 'Search'
      }
      multiple
      size="small"
      renderOption={(option: { label: string }) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {option.label}
        </div>
      )}
      renderInput={(params: any) => {
        const errorProps =
          props.errors?.[props.name] && props.touched?.[props.name]
            ? {
                error: true,
                helperText: props.errors?.[props.name],
              }
            : {};
        return (
          <MagicSearchInput
            {...params}
            label="ICD-10 code/diagnosis"
            variant="outlined"
            onChange={(e: { target: HTMLInputElement }) =>
              setInputValue(e.target.value)
            }
            multiline
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Icon
                  icon={icSearch}
                  size={24}
                  style={{
                    color: 'gray',
                    position: 'relative',
                    left: !!props.disabled ? '26px' : '52px',
                  }}
                />
              ),
            }}
            {...errorProps}
          />
        );
      }}
    />
  );
};
