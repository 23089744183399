import React, { useState, useEffect } from 'react';
import MagicSearchInput from '../generic/MagicSearchInput';
import { Autocomplete } from 'formik-material-ui-lab';
import { Field } from 'formik';
import { ic_search as icSearch } from 'react-icons-kit/md';
import Icon from 'react-icons-kit';

const getStatusLabel = (status: string) => {
  if (status === 'noResults') {
    return 'No results found';
  } else if (status === 'searching') {
    return 'Searching...';
  } else {
    return 'Search';
  }
};

type BodyPart = {
  BodyPartId: string;
  BodyPartName: string;
};

export const BodyPartSelect = (props: any) => {
  const [bodyParts, setBodyParts] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    fetchBodyParts();
  }, []);

  useEffect(() => {
    if (inputValue?.length > 3 && bodyParts?.length > 0) {
      setStatus('searching');
      const timeoutId = setTimeout(() => {
        setStatus('noResults');
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [inputValue, bodyParts?.length]);

  const fetchBodyParts = () => {
    setStatus('searching');
    fetch(
      `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/bodyparts`,
      {
        headers: { accept: 'application/json' },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(JSON.stringify(response.status));
        }

        return response.json();
      })
      .then((data) => {
        // artificial slow request
        // setTimeout(() => {
        setStatus(data.length > 0 ? 'idle' : 'noResults');
        setBodyParts(
          data.map((d: BodyPart) => {
            return {
              label: d.BodyPartName,
              id: d.BodyPartId,
              ...d,
            };
          })
        );
        // }, 7000);
      })
      .catch((error) => console.log('Error:', error));
  };

  return (
    <Field
      name={props.name}
      component={Autocomplete}
      id="body-part-select-search"
      disabled={props.disabled}
      options={bodyParts}
      getOptionLabel={(option: any) => option.BodyPartName}
      onChange={(event: any, value: any[]) => {
        props.setFieldValue(props.name, value);
      }}
      onBlur={() => {
        const touchedFields = { ...props.touched };
        touchedFields[props.name] = true;
        props.setTouched(touchedFields);
      }}
      noOptionsText={getStatusLabel(status)}
      multiple
      size="small"
      renderInput={(params: any) => {
        const errorProps =
          props.errors?.[props.name] && props.touched?.[props.name]
            ? {
                error: true,
                helperText: props.errors?.[props.name],
              }
            : {};
        return (
          <MagicSearchInput
            {...params}
            label="Body part"
            variant="outlined"
            onChange={(e: { target: HTMLInputElement }) =>
              setInputValue(e.target.value)
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Icon
                  icon={icSearch}
                  size={24}
                  style={{
                    color: 'gray',
                    position: 'relative',
                    left: !!props.disabled ? '26px' : '52px',
                  }}
                />
              ),
            }}
            multiline
            {...errorProps}
          />
        );
      }}
    />
  );
};
