import { makeStyles } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import classNames from 'classnames';
import styleVars from '../../../styleVars';

/* ---------------------------------------------------------------------------------------------- */
/*  Styles                                                                                        */
/* ---------------------------------------------------------------------------------------------- */

const useStyles = makeStyles({
  container: {
    position: 'relative',
    padding: '0px',
  },
  base: {
    '& .MuiInputBase-root': {
      paddingRight: 0,
      backgroundColor: styleVars.colorLightestGray,

      '& .MuiInputAdornment-positionEnd button': {
        padding: '10px',

        '& svg': {
          width: '18px',
          height: '18px',
        },
      },
    },
  },

  comboDatePicker: {
    '& .MuiInputBase-root': {
      paddingRight: 0,
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: 0,
      },
    },
  },

  keyboardDatePicker: {
    '& .MuiInputBase-root .MuiInputAdornment-positionEnd': {
      display: 'none',
      margin: 'normal',
    },
  },
});

export const MagicDateField = (props: KeyboardDatePickerProps) => {
  const classes = useStyles();
  const format = !props.format ? 'MM/DD/YYYY' : props.format;
  return (
    <Box className={classes.container}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          autoOk
          margin="dense"
          className={
            !props.className
              ? classNames(classes.base, classes.comboDatePicker)
              : props.className
          }
          fullWidth
          format={format}
          // inputVariant={!props.inputVariant ? 'outlined' : props.inputVariant}
          inputVariant="outlined"
          invalidDateMessage={
            !props.invalidDateMessage
              ? `Format as ${format}`
              : props.invalidDateMessage
          }
          size="small"
          placeholder={!props.placeholder ? format : props.placeholder}
          variant={!props.variant ? 'inline' : props.variant}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};
