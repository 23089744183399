import {Moment} from 'moment'
import {MedicalDiagnosisType} from './RequiredInfoTypes'
export type ReferralFormSchema = UserFormSchema & RequiredInfoFormSchema & AuthorizationFormSchema & AdditionalInfoSchema & FileUploads & MetaInfoSchema

export type UploadFileType = {
  path: string;
  lastModified: number;
  // lastModifiedDate: Thu Jul 27 2023 17:08:25 GMT-0500 (Central Daylight Time) {}
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

export type File = {
  name: string;
  // category: FileCategoryEnum | null;
  category: string | null;
  size: number;
  content: boolean;
  file: UploadFileType;
  fileId: string | null;
};

export interface FileUploads {
  uploadFiles?: File[]
}

export enum UserType {
  Adjuster = 'adjuster',
  ReferringDoctor = 'referringDoctor',
  CaseManager = 'caseManager',
  Other = 'other',
}

export type Adjuster = {
  FirstName: string;
  Email: string;
  LastName: string;
  Company: string;
  SalesforceId: string;
  id: string;
};

export type Physician = {
  Fax: string;
  NPI: string;
  Phone: string;
  LastName: string;
  FirstName: string;
  SalesforceId: string;
  bNotesReferringId: string;
  id: string;
  label: string;
};

export type CaseManager = {
  FirstName: string;
  LastName: string;
  Company: string;
  SalesforceId: string;
  WorkPhone: string;
  CellPhone: string;
  Email: string;
  id: string;
};

export type Employer = {
  label: string;
  id: string;
  Name: string;
  EmployerID: string;
  JobAnalysisPresent: string;
};


export type BodyPart = {
  BodyPartId: string;
  BodyPartName: string;
  id: string | undefined;
  label: string;
};

// type Diagnosis = {
//   diagnosisCode: string;
//   diagnosisDescription: string;
// };


export interface UserFormSchema {
    formStep?: number,
    userFirstName?: string,
    userLastName?: string,
    userEmail?: string,
    userCompany?: string,
    customUserInfo?: boolean,
    userPhonePreferred?: boolean,
    userEmailPreferred?: boolean,
    userPhoneNumber?: string,
    userPhoneType?: string,
    userPhoneExtension?: string,
    userType?: UserType | undefined,
    userRememberMe?: boolean,
    userAdjuster?: Adjuster,
    userReferringPhysician?: Physician,
    userCaseManager?: CaseManager,
    userRoleInCase?: string,
    userNewOrExisting?: string,
    adjusterEmailSearch?: string,
    caseManagerEmailSearch?: string,
    physicianPhoneSearch?: string,
    physicianFaxSearch?: string,
    physicianLastNameSearch?: string,
    emailSearch?: string,
}

    export interface RequiredInfoFormSchema {
    formStep?: number,
    patientFirstName?: string,
    patientLastName?: string,
    patientBirthday?: string | Date | Moment | undefined,
    patientPhoneNumber?: string,
    patientSex?: string,
    patientPrimaryLanguage?: string,
    interpreterAuthorized?: boolean,
    transportationAuthorized?: boolean,
    claimNumber?: string,
    stateOfJury?: string,
    caseType?: string,
    specialty?: string,
    treatingPhysician?: Physician,
    dateOfInjury?: string | Date | Moment | undefined,
    checkBoxForPhysicianInfo?: boolean,
    physicianFirstName?: string,
    physicianLastName?: string,
    physicianEmail?: string,
    checkBoxEmailPreferred?: boolean,
    physicianPhoneNumber?: string,
    physicianPhoneType?: string,
    physicianPhoneExtension?: string,
    checkBoxPhysicianPhonePreferred?: boolean,
    checkboxEmailPreferred?: boolean,
    medicalDiagnosis?: MedicalDiagnosisType[], 
    bodyPart?: BodyPart[],
    hasProgram?: boolean,
    programId?: string,
    programRisk?: string,
    surgical?: string,
    employerSelected?: Employer, 
    jobTitle?: string,
    checkBoxForEmployer?: boolean,
    employerCompany?: string,
    employerContactName?: string,
    employerAddress?: string,
    employerCity?: string,
    employerState?: string,
    employerZipCode?: string,
    employerEmail?: string,
    checkBoxEmployerEmailPreferred?: boolean,
    employerPhoneNumber?: string,
    employerPhoneType?: string,
    employerPhoneExtension?: string,
    checkBoxEmployerPhonePreferred?: boolean,
    }

    export interface AuthorizationFormSchema {
    formStep?: number,
    authScriptDate?: string | Date | Moment | undefined,
    authAuthorizedVisits?: string,
    authVisitTimes?: string,
    authVisitWeeks?: string,
    authAuthorizedBy?: string,
    authAdditionalInjuryDate?: string | Date | Moment | undefined,
    authReturnToDoctorDate?: string | Date | Moment | undefined,
    authAuthorizationNumber?: string ,
    }

    export interface AdditionalInfoSchema {
    formStep?: number,
    selectAdjuster?: any,
    selectCaseManager?: any,
    // Checkboxes for adding custom details
    customCaseManagerInfo?: boolean,
    customAdjusterInfo?: boolean,
    // Patient Form
    patientPreferredName?: string,
    patientMiddleName?: string,
    patientSuffix?: string,
    patientAddress?: string | undefined,
    patientCity?: string,
    patientState?: string,
    patientZip?: string,
    patientMaritalStatus?: string,
    patientRace?: string,
    patientEthnicity?: string,
    patientPreferredLanguage?: string,
    patientEmergencyContactName?: string,
    patientEmergencyContactRelationship?:
      string,
    patientEmergencyContactPhoneNumber?: string,
    patientPreferredClinic?: string,
    patientPreferredClinician?: string,
    patientOtherInfo?: string,
    // Adjuster fold out form
    adjusterFirstName?: string,
    adjusterLastName?: string,
    adjusterEmail?: string,
    adjusterCompany?: string,
    adjusterUserInfo?: boolean,
    adjusterPhonePreferred?: boolean,
    adjusterEmailPreferred?: boolean,
    adjusterPhoneNumber?: string,
    adjusterPhoneType?: string,
    adjusterPhoneExtension?: string,
    // Case Manager fold out form
    caseManagerFirstName?: string,
    caseManagerLastName?: string,
    caseManagerEmail?: string,
    caseManagerCompany?: string,
    caseManagerPhonePreferred?: boolean,
    caseManagerEmailPreferred?: boolean,
    caseManagerPhoneNumber?: string,
    caseManagerPhoneType?: string,
    caseManagerPhoneExtension?: string,
    }

    export interface MetaInfoSchema {
      referralId?: string,
      dateOfReferral?: string, 
    }