import React, { FC, SyntheticEvent, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { TextField, TextFieldProps } from 'formik-material-ui';
import MaskedInput from 'react-text-mask';
import styleVars from '../../../styleVars';

const useStyles = makeStyles({
  textarea: {
    '& .MuiInputBase-root': {
      backgroundColor: styleVars.colorLightestGray,
    },
  },
});

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

export const GetPhoneValue = (event: SyntheticEvent) => {
  const { value } = event?.target as HTMLInputElement;
  const strippedValue: string = value?.replace(/\D/g, '').trim();
  return +strippedValue || '';
};

export const PhoneMask = forwardRef<MaskedInput, TextMaskCustomProps>(
  (props: TextMaskCustomProps, ref: any) => {
    return (
      <MaskedInput
        {...props}
        ref={ref}
        mask={[
          '(',
          /[1-9]/,
          /\d/,
          /\d/,
          ')',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        placeholderChar={'\u2000'}
      />
    );
  }
);

export type MagicPhoneFieldProps = TextFieldProps & {
  label?: string;
  name: string;
  value: any;
};

export const MagicPhoneField: FC<MagicPhoneFieldProps> = ({
  label,
  name,
  InputProps,
  value,
  ...props
}) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.textarea}
      fullWidth
      InputProps={{
        inputComponent: PhoneMask as any,
        ...InputProps,
      }}
      InputLabelProps={{ shrink: !!value }}
      margin="dense"
      label={label}
      variant="outlined"
      {...props}
    />
  );
};
