import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Link,
} from '@material-ui/core';

import { useNavigate } from 'react-router-dom';
import styleVars from '../../styleVars';

const FormLayout = (props: {
  children: JSX.Element;
  helpCardBorderColor?: string;
}) => {
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item md={8} sm={12} style={{ marginRight: '30px' }}>
        {props.children}

        <Box height="45px"></Box>
      </Grid>
      <Grid item sm={8} md={3}>
        <Card
          style={{
            borderTop: `5px solid ${props.helpCardBorderColor || '#FFCC29'}`,
            borderRadius: '0px',

            backgroundColor: styleVars.colorCardBackground,
          }}
        >
          <CardContent>
            <Grid container>
              <Grid container>
                <Box height="30px"></Box>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <Typography
                  style={{
                    color: styleVars.colorThemeBlue,
                    fontSize: '30px',
                    fontWeight: 400,
                  }}
                >
                  Need help?
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Box height="45px"></Box>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <Typography
                  variant="body1"
                  style={{
                    color: styleVars.colorLightGray,
                    fontWeight: 500,
                    fontSize: styleVars.fontSizeLarge,
                  }}
                >
                  Phone: (913) 236-3551
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <Typography
                  variant="body1"
                  style={{
                    color: styleVars.colorLightGray,
                    fontWeight: 500,
                    fontSize: styleVars.fontSizeLarge,
                  }}
                >
                  Fax: (913) 236-3559
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <Typography
                  variant="body1"
                  style={{
                    color: styleVars.colorLightGray,
                    fontWeight: 500,
                    fontSize: styleVars.fontSizeLarge,
                  }}
                >
                  Email:{' '}
                  <Link
                    onClick={() => {
                      return process.env.REACT_APP_QA_ENABLED === 'true'
                        ? navigate('/referral/select')
                        : (window.location.href = 'mailto:example@example.com');
                    }}
                    style={{
                      cursor: 'pointer',
                      color: styleVars.colorThemeBlue,
                    }}
                  >
                    referrals@bardavon.com
                  </Link>
                </Typography>
              </Grid>
              <Grid container>
                <Box height="30px"></Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormLayout;
