// import { ReferralFormSchema } from "../../types/ReferralFormSchema";
import UserFormValidation from '../yup/UserFormValidation'
import RequiredInfoFormValidation from '../yup/RequiredInfoFormValidation'
import AuthorizationFormValidation from '../yup/AuthorizationFormValidation'
import AdditionalInfoFormValidation from '../yup/AdditionalInfoFormValidation'
import { ReferralFormSchema } from '../types/ReferralFormSchema'
import { StakeholderValidation, OptionalPatientValidation, OtherValidation} from '../yup/ReviewValidations'

export enum SectionType {
    User = 'user',
    Required = 'required',
    Authorization = 'authorization',
    Additional = 'additional',
    Stakeholder = 'stakeholder',
    PatientOptional = 'patientOptional',
    Other= 'other'
  }
  

export const validateEntireForm = (formValues: ReferralFormSchema) => {
    const validationSchemas = [UserFormValidation, RequiredInfoFormValidation, AuthorizationFormValidation, AdditionalInfoFormValidation]
        let errors: {} = {}
        const merged = validationSchemas.slice(1).reduce(
            (mergedSchemas: any, schema: any) => mergedSchemas.concat(schema),
            validationSchemas[0] 
        );
        merged.validate(formValues, {abortEarly: false})

            .catch((err: any) => {

                errors = err.inner.reduce((acc: any, currentError: {message: string, path: string}) =>{
                    acc[currentError.path] = currentError.message;
                    return acc
                }, {})
            })
            return errors
}

export const validateEntireFormInSections = async (formValues: ReferralFormSchema) => {
    let errors: { errors: any, sections: SectionType[] } = { errors: {}, sections: [] };

    const validationSchemas = [
        { schema: UserFormValidation, section: SectionType.User },
        { schema: RequiredInfoFormValidation, section: SectionType.Required},
        { schema: AuthorizationFormValidation, section: SectionType.Authorization },
        { schema: AdditionalInfoFormValidation, section: SectionType.Additional},
        { schema: StakeholderValidation, section: SectionType.Stakeholder},
        { schema: OptionalPatientValidation, section: SectionType.PatientOptional },
        { schema: OtherValidation, section: SectionType.Other },
    ];

    // Map over the validationSchemas and return an array of promises
    const validationPromises = validationSchemas.map(async (currentSchema: any) => {
        try {
            await currentSchema.schema.validate(formValues, { abortEarly: false });
        } catch (err: any) {
            const currentErrors = err.inner.reduce((acc: any, currentError: { message: string, path: string }) => {
                acc[currentError.path] = currentError.message;
                return acc;
            }, {});

            errors.errors = { ...errors.errors, ...currentErrors };
            errors.sections = [currentSchema.section, ...errors.sections];
        }
    });

    // Wait for all promises to resolve/reject
    await Promise.all(validationPromises);

    return errors;
};