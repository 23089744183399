interface GenericResultDTO {
  SalesforceId: string
  FirstName: string
  LastName: string
  Company: string
  Email: string
}

export interface AdjusterDTO extends GenericResultDTO {}
export interface CaseManagerDTO extends GenericResultDTO {
  bNotesCaseManagerId: number
  WorkPhone?: string
  CellPhone?: string
  CaseManagerPreferredContact?: string
  CaseManagerFax?: string
}

export interface PhysicianDTO extends GenericResultDTO {
  bNotesCaseManagerId: number
  WorkPhone?: string
  CellPhone?: string
  CaseManagerPreferredContact?: string
  CaseManagerFax?: string
}

// TODO: Update with referring doctor types

interface FetchGenericMatchesResponse {
  data: AdjusterDTO[] | CaseManagerDTO[]
}

export const fetchAdjusterMatches = async (inputValue: string) => {
  return await fetchGenericMatches(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/adjusters/${inputValue}`
  )
}
export const fetchCaseManagerMatches = async (inputValue: string) => {
  return await fetchGenericMatches(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/casemanagers/${inputValue}`
  )
}
export const fetchReferringDoctorMatches = async (lastName: string, contactNumber: string, contactType: string) => {
  const urlSuffix = contactType === 'phone' ? `&phone=${contactNumber}` : `&fax=${contactNumber}`
  return await fetch(`${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/physicians?last_name=${lastName}${urlSuffix}`,
    { headers: { accept: 'application/json' }}
  ).then((response) => {
    if (!response.ok) {
      throw new Error(JSON.stringify(response.status));
    }
    return response.json()
  }).then((data) => {
    return {
      data: data
    }
  }).catch((error) => {
    console.warn('fetch physicians by phone error:', error)
    return Promise.reject()
  });
}

const fetchGenericMatches = async (endpoint: string): Promise<FetchGenericMatchesResponse> => {
  return await fetch(endpoint,
    { headers: { accept: 'application/json' } }
  ).then((response) => {
    if (!response.ok) {
      throw new Error(JSON.stringify(response.status));
    }
    return response.json()
  }).then((data) => {
    return {
      data: data
    }
  }).catch((error) => {
    console.warn('fetchGenericMatches error:', error)
    return Promise.reject()
  });
}

