import React, { useContext } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';
import { FormProvider } from '../../contexts/FormContext';
import { FormContext } from '../../contexts/FormContext';
import moment from 'moment';
import UserForm from '../forms/UserForm';
import FormLayout from '../forms/FormLayout';
import RequiredInfoForm from '../forms/RequiredInfoForm';
import AdditionalInfoForm from '../forms/AdditionalInfoForm';
import AuthorizationForm from '../forms/AuthorizationForm';
import ReviewForm from '../forms/ReviewForm';
import CompletedForm from '../forms/CompletedForm';
import styleVars from '../../styleVars';
import { UserType } from '../../types/ReferralFormSchema';

const FormSelect = () => {
  const navigate = useNavigate();
  const { formValues, setFormValues } = useContext(FormContext);

  const fillStepOne = () => {
    const fillValues = {
      customUserInfo: true,
      // formStep: 3,
      userAdjuster: undefined,
      userCaseManager: undefined,
      userCompany: 'Fake Company',
      userEmail: 'john@fakecompany.com',
      userEmailPreferred: true,
      userFirstName: 'John',
      userLastName: 'James',
      userPhoneExtension: '3333',
      userPhoneNumber: '(232) 323-2333',
      userPhonePreferred: false,
      userPhoneType: 'M',
      userReferringPhysician: undefined,
      userRememberMe: true,
      userType: UserType.Adjuster,
      userNewOrExisting: 'new',
      userRoleInCase: undefined,
    };
    setFormValues({ ...formValues, ...fillValues });
  };

  const fillStepTwo = () => {
    const fillValues = {
      // "formStep": 2,
      patientFirstName: 'Jim',
      patientLastName: 'Jonathan',
      patientBirthday: moment('1980-07-05'),
      patientPhoneNumber: '(333) 333-3333',
      patientSex: 'M',
      jobTitle: 'Stocker',
      patientPrimaryLanguage: 'English',
      interpreterAuthorized: true,
      transportationAuthorized: true,
      claimNumber: 'A33B6VB43',
      stateOfJury: 'GA',
      caseType: 'PT',
      specialty: 'Telerehab',
      treatingPhysician: undefined,
      dateOfInjury: '2023-07-01T21:55:00.000Z',
      checkBoxForPhysicianInfo: true,
      physicianFirstName: 'William',
      physicianLastName: 'James',
      physicianEmail: 'bill@bsw.com',
      checkBoxEmailPreferred: false,
      physicianPhoneNumber: '(234) 344-3444',
      physicianPhoneType: 'M',
      physicianPhoneExtension: '3333',
      checkBoxPhysicianPhonePreferred: true,
      medicalDiagnosis: [
        {
          label: 'G89.11 - Acute pain due to trauma',
          id: 'G89.11',
          combined: 'G89.11 - Acute pain due to trauma',
          diagnosisCode: 'G89.11',
          diagnosisDescription: 'Acute pain due to trauma',
        },
      ],
      bodyPart: [
        {
          label: 'Arm',
          id: 'D001.5',
          BodyPartId: 'D001.5',
          BodyPartName: 'Arm',
        },
      ],
      surgical: 'surgical',
      employerSelected: undefined,
      checkBoxForEmployer: true,
      employerCompany: 'Work Ltd',
      employerContactName: 'Phil Johnson',
      employerAddress: '2121 East West Avenue Drive',
      employerCity: 'Dallas',
      employerState: 'MA',
      employerZipCode: '55555',
      employerEmail: 'phil@workltd.com',
      checkBoxEmployerEmailPreferred: true,
      employerPhoneNumber: '(765) 456-2233',
      employerPhoneType: 'W',
      employerPhoneExtension: '4444',
      checkBoxEmployerPhonePreferred: false,
    };
    setFormValues({ ...formValues, ...fillValues });
  };

  const fillStepThree = () => {
    const fillValues = {
      authScriptDate: '2023-07-01T22:04:00.000Z',
      authAuthorizedVisits: '1',
      authVisitTimes: '1',
      authVisitWeeks: '1',
      authAuthorizedBy: 'Adjuster',
      authAdditionalInjuryDate: '2023-07-01T22:04:00.000Z',
      authReturnToDoctorDate: '2023-07-01T22:04:00.000Z',
      authAuthorizationNumber: 'A5G1BG71',
    };
    setFormValues({ ...formValues, ...fillValues });
  };

  const fillStepFour = () => {
    const fillValues = {
      customCaseManagerInfo: true,
      customAdjusterInfo: true,
      patientPreferredName: 'Carlo',
      patientMiddleName: 'Bill',
      patientSuffix: 'iii',
      patientAddress: '2121 West Avenue Drive',
      patientCity: 'Dallas',
      patientState: 'ME',
      patientZip: '66445',
      patientMaritalStatus: 'M',
      patientRace: '2028-9',
      patientEthnicity: '2135-2',
      patientPreferredLanguage: 'ENG',
      patientEmergencyContactName: 'John Johnson',
      patientEmergencyContactRelationship: 'Spouse',
      patientEmergencyContactPhoneNumber: '(232) 323-4333',
      patientPreferredClinic: 'Athletic Health Medical',
      patientPreferredClinician: 'James Nathan',
      patientOtherInfo: 'NA',
      adjusterFirstName: 'Dale',
      adjusterLastName: 'Henson',
      adjusterEmail: 'dale@firstinsurance.com',
      adjusterCompany: 'First Insurance',
      adjusterUserInfo: true,
      adjusterPhonePreferred: false,
      adjusterEmailPreferred: true,
      adjusterPhoneNumber: '(765) 899-0104',
      adjusterPhoneType: 'M',
      adjusterPhoneExtension: '2222',
      caseManagerFirstName: 'Carl',
      caseManagerLastName: 'Simmons',
      caseManagerEmail: 'carl@tcby.com',
      caseManagerCompany: 'TCBY',
      caseManagerPhonePreferred: false,
      caseManagerEmailPreferred: true,
      caseManagerPhoneNumber: '(435) 671-1199',
      caseManagerPhoneType: 'M',
      caseManagerPhoneExtension: '222',
    };
    setFormValues({ ...formValues, ...fillValues });
  };

  const addFakeFiles = () => {
    const fillValues = {
      uploadFiles: [
        {
          name: 'MCF.png',
          category: "Doctor's note(s)",
          size: 50,
          content: true,
          file: {} as any,
          fileId: '12',
        },
        {
          name: 'MyCoolFile.png',
          category: 'Certified letter',
          size: 50,
          content: true,
          file: {} as any,
          fileId: '51',
        },
      ],
    };
    setFormValues({ ...formValues, ...fillValues });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Button
          variant="contained"
          onClick={() => navigate('/referral/user')}
          color="primary"
        >
          Step 1 (User)
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={() => navigate('/referral/requiredInfo')}
          color="primary"
        >
          Step 2 (Required Info)
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={() => navigate('/referral/authorization')}
          color="primary"
        >
          Step 3 (Authorization)
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={() => navigate('/referral/additionalInfo')}
          color="primary"
        >
          Step 4 (Additional Info)
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={() => navigate('/referral/review')}
          color="primary"
        >
          Step 5 (Review)
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={() => navigate('/referral/complete')}
          color="primary"
        >
          Step 6 (Completed)
        </Button>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box>
          <Button
            variant="contained"
            onClick={fillStepOne}
            // onClick={() => navigate('/referral/user')}
            color="secondary"
          >
            Fill Step 1 (User)
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={fillStepTwo}
            // onClick={() => navigate('/referral/requiredInfo')}
            color="secondary"
          >
            Fill Step 2 (Required Info)
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={fillStepThree}
            // onClick={() => navigate('/referral/authorization')}
            color="secondary"
          >
            Fill Step 3 (Authorization)
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={fillStepFour}
            // onClick={() => navigate('/referral/additionalInfo')}
            color="secondary"
          >
            Fill Step 4 (Additional Info)
          </Button>
        </Box>
        <Box>
          <Button variant="contained" onClick={addFakeFiles} color="secondary">
            Add Fake Files
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={() => localStorage.removeItem('userData')}
            // onClick={() => navigate('/referral/additionalInfo')}
            color="secondary"
          >
            Clear User Data
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const ReferralForm = () => {
  const matches = useMatches();
  const FormComponent = () => {
    switch (matches[matches.length - 1].pathname || '') {
      case '/referral/user':
        return (
          <FormLayout>
            <UserForm />
          </FormLayout>
        );
      case '/referral/requiredInfo':
        return (
          <FormLayout>
            <RequiredInfoForm fileOpenOnMount={false} />
          </FormLayout>
        );
      case '/referral/requiredInfo/file':
        return (
          <FormLayout>
            <RequiredInfoForm fileOpenOnMount={true} />
          </FormLayout>
        );
      case '/referral/authorization':
        return (
          <FormLayout>
            <AuthorizationForm />
          </FormLayout>
        );
      case '/referral/additionalInfo':
        return (
          <FormLayout helpCardBorderColor={styleVars.colorGreen}>
            <AdditionalInfoForm />
          </FormLayout>
        );
      case '/referral/review':
        return (
          <FormLayout>
            <ReviewForm />
          </FormLayout>
        );
      case '/referral/complete':
        return (
          <FormLayout>
            <CompletedForm />
          </FormLayout>
        );
      case '/referral/select':
        return process.env.REACT_APP_QA_ENABLED === 'true' ? (
          <FormSelect />
        ) : (
          <FormLayout>
            <UserForm />
          </FormLayout>
        );

      default:
        return (
          <FormLayout>
            <UserForm />
          </FormLayout>
        );
    }
  };

  return (
    <h1>
      {
        <FormProvider>
          <FormComponent />
        </FormProvider>
      }
    </h1>
  );
};

export default ReferralForm;
