/**
 * Send a GET with URL params that will notify the referral API to in turn call
 * the Web-to-PDF lambda to ensure that by the time we get to the end of the 
 * referral form submission, the service is up and ready to respond.
 */
export async function wakeupPdfLambda() {
    const pdfWakeupUrl = `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/pdf?wake=wakeup!`;
    try {
        await fetch(pdfWakeupUrl);
    }
    catch(error) {
        console.error(error);
    }
}
