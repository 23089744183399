import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/referral/user');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <></>;
};

export default Home;
