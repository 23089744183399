import moment, {Moment} from 'moment'
  
  export const isMoment = (obj: any): obj is Moment => {
    return moment.isMoment(obj);
  }
  
  export const momentStripTimestamp = (datetime: string | null | undefined | Moment | Date) => {
      if (isMoment(datetime)) {
          // Now TypeScript knows datetime is a Moment object in this block
          return datetime.format('YYYY-MM-DD');
      }
      else if (typeof datetime === 'string' && datetime) {
          // TypeScript knows that datetime is a string in this block
          const date = datetime.split("T")[0];
          return date;
      }
  
      // Handle null or undefined
      return null;
  }

export const getReferrerType = (referrerType: string | undefined) => {
    if(referrerType === 'adjuster'){
        return 'Adjuster'
    }
    else if(referrerType === 'caseManager'){
        return 'Case manager'
    }
    else if(referrerType === 'referringDoctor'){
        return 'Referring doctor'
    }
    else if(referrerType === 'other'){
        return 'Other'
    }
    else return null
}

export const getFormattedPatientGender = (gender: string | null) => {
    if(gender === 'M'){
        return 'Male'
    }
    else if (gender === 'F'){
        return 'Female'
    }
    else {
        return null
    }
}

export const getReferrerId = (userType: string | undefined, adjusterId: string | undefined, caseManagerId: string | undefined, referringPhysicianId: string | undefined) =>{
    switch(userType){
        case 'adjuster':
            return  !!adjusterId ? String(adjusterId) : null
        case 'caseManager':
            return !!caseManagerId ? String(caseManagerId) : null
        case 'referringDoctor':
            return !!referringPhysicianId ? String(referringPhysicianId) : null
        default:
            return null
    }
}

export const getContactPreference = (phonePreferred: boolean | undefined, emailPreferred: boolean | undefined) => {
    if(!!phonePreferred){
        return 'Phone'
    }
    else if(!!emailPreferred){
        return 'email'
    }
    else {
        return null
    }
}

export const getPhoneType = (phoneType: string | undefined) => {
    if(phoneType === 'H'){
        return 'Home'
    }
    else if(phoneType === 'W'){
        return 'Work'
    }
    else if(phoneType === 'M'){
        return 'Mobile'
    }
    else return null
}

export const getCaseType = (caseType: string | undefined) => {
    if(caseType === 'PT'){
        return 'Physical Therapy'
    }
    else if(caseType === 'OT'){
        return 'Occupational Therapy'
    }
    else if(caseType === 'WC'){
        return 'Work Conditioning'
    }
    else if(caseType === 'FC'){
        return 'Functional Capacity Evaluation'
    }
    else if(caseType === 'EM'){
        return 'Employment Testing'
    }
    else return null
}

export const getRace = (race: string | undefined) => {
    if(race === '0000-0'){
        return 'Declined to Provide'
    }
    else if(race === '1002-5'){
        return 'American Indian or Alaska Native'
    }
    else if(race === '2028-9'){
        return 'Asian'
    }
    else if(race === '2054-5'){
        return 'Black or African American'
    }
    else if(race === '2076-8'){
        return 'Native Hawaiian or Other Pacific Islander'
    }
    else if(race === '2106-3'){
        return 'White'
    }
    else if(race === '2131-1'){
        return 'Some Other Race'
    }
    else if(race === '9999-9'){
        return 'Race Unknown'
    }
    else return null
}

export const getMaritalStatus = (maritalStatus: string | undefined) => {
    if(maritalStatus === 'M'){
        return 'Married'
    }
    else if(maritalStatus === 'S'){
        return 'Single'
    }
    else if(maritalStatus === 'D'){
        return 'Divorced'
    }
    else if(maritalStatus === 'W'){
        return 'Widowed'
    }
    else if(maritalStatus === 'E'){
        return 'Separated'
    }
    else if(maritalStatus === 'N'){
        return 'Never Married'
    }
    else return null
}

export const getEthnicity = (ethnicity: string | undefined) => {
    if(ethnicity === '0000-0'){
        return 'Declined to Provide'
    }
    else if(ethnicity === '2135-2'){
        return 'Hispanic or Latino'
    }
    else if(ethnicity === '9999-9'){
        return 'Ethnicity Unknown'
    }
    else if(ethnicity === '2186-5'){
        return 'Not Hispanic or Latino'
    }
    else return null
}

export const getSpecialty = (specialty: string | undefined) => {
    if(specialty === 'Telerehab'){
        return 'Telerehab'
    }
    else if(specialty === 'Home Health'){
        return 'Home Health'
    }
    else if(specialty === 'Hybrid'){
        return 'Hybrid'
    }
    else if(specialty === 'WC/WH'){
        return 'WC/WH'
    }
    else if(specialty === 'FCE'){
        return 'FCE'
    }
    else if(specialty === 'Chiropractic'){
        return 'Chiropractic'
    }
    else if(specialty === 'Acupuncture'){
        return 'Acupuncture'
    }
    else return null
}

export const getSurgicalStatus = (status: string | undefined) => {
    if(status === 'surgical'){
        return 'Surgical'
    }
    else if(status === 'nonsurgical'){
        return 'Non-Surgical'
    }
    else if(status === 'unknown'){
        return 'Unknown'
    }
    else return null
}

export const getEmergencyContactRelationship = (relationship: string | undefined) => {
    if(relationship === 'Spouse'){
        return 'Spouse'
    }
    else if(relationship === 'Parent'){
        return 'Parent'
    }
    else if(relationship === 'Significant other'){
        return 'Significant Other'
    }
    else if(relationship === 'Friend'){
        return 'Friend'
    }
    else return null
}