import React, { useContext, useEffect, useState, MouseEvent } from 'react';
import { FormContext } from '../../contexts/FormContext';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Box,
  Grid,
  Typography,
  Link,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Icon from 'react-icons-kit';
import { NOVALUE } from '../../constants/textConstants';
import languageChoices from '../../constants/languageChoices';
import states from '../../constants/states';

import ethnicityChoices from '../../constants/ethnicityChoices';
import patientNameSuffixChoices from '../../constants/suffixChoices';
import raceChoices from '../../constants/raceChoices';
import maritalStatusChoices from '../../constants/maritalChoices';

import { submitReferral } from '../../helpers/submitReferral';
import { clearForm } from '../../helpers/clearForm';

import styleVars from '../../styleVars';
import DialogContainer from '../dialogs/DialogContainer';
import { BodyPart, File } from '../../types/ReferralFormSchema';
import {
  formatDate,
  getContactPreference,
  getSurgicalFormattedNames,
  getSelectNameProperty,
  getReferrerName,
  getReferrerPhone,
  getReferrerEmail,
  getCaseType,
  getPatientGender,
  getTreatingPhysician,
  formatVisitText,
  formatAddress,
  getUserTypeLabel,
} from '../../helpers/dataFormatters';

import { validateEntireFormInSections } from '../../helpers/validateEntireForm';
import { SectionType } from '../../helpers/validateEntireForm';
import { ic_error as icError } from 'react-icons-kit/md';
import { CancelDialog } from '../dialogs/CancelDialog';
import UploadDialog from '../dialogs/UploadDialog';

import { MedicalDiagnosisType } from '../../types/RequiredInfoTypes';

import './ReviewForm.scss';
import { Dialog, Modal } from '@mui/material';

const classes = {
  link: 'link',
  leftAlign: 'left-align',
  divider: 'divider',
  cell: 'cell',
  leftCell: 'left-cell',
  forceMaxWidth: 'force-max-width',
  table: 'table',
};

enum DialogOptions {
  ServerError = 'serverError',
  ValidationError = 'validationError',
  UploadWarning = 'uploadWarning',
  UploadManagement = 'uploadManagement',
}

const ReviewForm = () => {
  const navigate = useNavigate();
  const { formValues, setFormValues } = useContext(FormContext);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<{
    errors: any;
    sections: string[];
  }>({ errors: {}, sections: [] });
  const [shownDialog, setShownDialog] = useState(DialogOptions.ServerError);
  const [uploadWarningAcknowledged, setUploadWarningAcknowledged] = useState(false);
  const [submitOnDialogClose, setSubmitOnDialogClose] = useState(false);

  useEffect(() => {
    if (Number(formValues?.formStep) < 5 || !formValues?.formStep) {
      setFormValues({
        ...formValues,
        formStep: 5,
        customAdjusterInfo:
          formValues?.userNewOrExisting === 'existing'
            ? !!formValues?.customAdjusterInfo
            : true,
        customCaseManagerInfo:
          formValues?.userNewOrExisting === 'existing'
            ? !!formValues?.customCaseManagerInfo
            : true,
        checkBoxForPhysicianInfo:
          formValues?.userNewOrExisting === 'existing'
            ? !!formValues?.checkBoxForPhysicianInfo
            : true,
        checkBoxForEmployer:
          formValues?.userNewOrExisting === 'existing'
            ? !!formValues?.checkBoxForEmployer
            : true,
      });
    }
  }, [formValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    validateEntireFormInSections(formValues).then((errors) => {
      setFormErrors(errors);
    });
  }, [formValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Number(formValues?.formStep) < 5) {
      setFormValues({ ...formValues, formStep: 5 });
    }
  }, [formValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitReferralForm = async (toggleDialog: () => void) => {
    setSubmitting(true);
    try {
      const submissionResponse = await submitReferral(formValues);
      setFormValues({
        ...formValues,
        referralId: submissionResponse?.referral_id,
      });
      navigate('/referral/complete');
    } catch (error) {
      setShownDialog(DialogOptions.ServerError);
      toggleDialog();
      console.error(error);
    }
    setSubmitting(false);
  };

  const onSubmitClick = async (e: any, toggleDialog: () => void) => {
    e.preventDefault();
    if (formErrors?.sections.length > 0) {
      setShownDialog(DialogOptions.ValidationError);
      toggleDialog();
      return;
    } else if (!formValues?.uploadFiles?.length && !uploadWarningAcknowledged) {
      setShownDialog(DialogOptions.UploadWarning);
      setUploadWarningAcknowledged(true);
      toggleDialog();
      return;
    }
    submitReferralForm(toggleDialog);
  };

  return (
    <div className="review-form">
      <Grid item xs={12}>
        <Box style={{ width: '925px' }}></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          style={{
            color: styleVars.colorThemeBlue,
            fontSize: '30px',
          }}
        >
          Please review your referral
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="28px"></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{
            fontSize: styleVars.fontSizeNormal,
            fontWeight: 500,
            color: styleVars.colorLightGray,
          }}
        >
          Please review your referral below. If everything looks good, click
          "Submit." If you need to change something, click the "Edit" link
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="30px"></Box>
      </Grid>
      <Grid item xs={10}>
        <Box className={classes.divider} height="1px"></Box>
      </Grid>
      <Grid container spacing={2}>
        <Box height="28px"></Box>
      </Grid>
      <Grid item xs={3}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                <div className={classes.forceMaxWidth}>
                  <Typography
                    variant="h6"
                    style={{
                      color: formErrors?.sections.includes(SectionType.User)
                        ? styleVars.colorError
                        : styleVars.colorGreen,
                      fontSize: styleVars.fontSizeXxlarge,
                      fontWeight: 500,
                    }}
                  >
                    Your info
                  </Typography>
                </div>
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    navigate('/referral/user');
                  }}
                >
                  Edit
                </Link>
                {formErrors?.sections.includes(SectionType.User) && (
                  <Typography
                    component="span"
                    variant="body2"
                    className="missing-info-text"
                  >
                    {'  '}(Info Missing)
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Role:
              </TableCell>
              {/* We are going to have to clean up some of the select values such as this */}
              <TableCell size="small" className={classes.cell}>
                {getUserTypeLabel(formValues?.userType) || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Name:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getReferrerName(formValues)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Email:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getReferrerEmail(formValues)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Preferred contact:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getContactPreference(
                  formValues?.userPhonePreferred,
                  formValues?.userEmailPreferred
                )}
              </TableCell>
            </TableRow>
            {/* Additional phone is allowed so conditional showing necessary */}
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Phone:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getReferrerPhone(formValues)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Attachments:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {
                  <DialogContainer
                    hideCloseButton
                    // classes=""
                    dialogTrigger={({ toggleDialog }) =>
                      formValues?.uploadFiles?.length ? (
                        <>
                          {formValues?.uploadFiles.map((f: File, i: number) => {
                            return (
                              <Typography
                                component="span"
                                variant="body1"
                                key={`${f?.name}${i}`}
                              >
                                <Link
                                  component="button"
                                  variant="body1"
                                  underline="none"
                                  onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    toggleDialog();
                                  }}
                                >
                                  {f?.name}
                                </Link>{' '}
                                ({f?.category}){' '}
                                {Number(formValues?.uploadFiles?.length) > 0 &&
                                  i !==
                                  Number(formValues?.uploadFiles?.length) -
                                  1 &&
                                  '|'}{' '}
                              </Typography>
                            );
                          })}
                        </>
                      ) : (
                        <Link
                          component="button"
                          variant="body1"
                          underline="none"
                          style={{ fontSize: '16px' }}
                          onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            toggleDialog();
                          }}
                        >
                          upload documents
                        </Link>
                      )
                    }
                    dialogContent={({ toggleDialog }) => (
                      <UploadDialog toggleDialog={toggleDialog} />
                    )}
                  />
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs={9}></Grid>
      <Grid container spacing={2}>
        <Box height="40px"></Box>
      </Grid>
      <Grid item xs={3}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.cell}>
                <div className={classes.forceMaxWidth}>
                  <Typography
                    variant="h6"
                    style={{
                      color: formErrors?.sections.includes(SectionType.Required)
                        ? styleVars.colorError
                        : styleVars.colorGreen,
                      fontSize: styleVars.fontSizeXxlarge,
                      fontWeight: 500,
                    }}
                  >
                    Referral information
                  </Typography>
                </div>
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    navigate('/referral/requiredInfo');
                  }}
                >
                  Edit
                </Link>
                {formErrors?.sections.includes(SectionType.Required) && (
                  <Typography
                    component="span"
                    variant="body2"
                    className="missing-info-text"
                  >
                    {'  '}(Info Missing)
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Patient name:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.patientFirstName && formValues?.patientLastName
                  ? `${formValues?.patientFirstName} ${formValues?.patientLastName}`
                  : NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Birthday:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formatDate(formValues?.patientBirthday)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Gender:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getPatientGender(formValues?.patientSex) || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Phone number:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.patientPhoneNumber || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Primary language:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getSelectNameProperty(
                  formValues?.patientPrimaryLanguage,
                  languageChoices
                ) || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Interpreter authorized:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.interpreterAuthorized ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Transportation authorized:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.transportationAuthorized ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Claim number:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.claimNumber || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                State of jurisdiction:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getSelectNameProperty(formValues?.stateOfJury, states) ||
                  NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Injury date:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formatDate(formValues?.dateOfInjury)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Treating physician:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getTreatingPhysician(formValues) || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Case type:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getCaseType(formValues?.caseType) || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Specialty:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.specialty || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Diagnosis/ICD-10:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.medicalDiagnosis?.length
                  ? formValues?.medicalDiagnosis.map(
                    (m: MedicalDiagnosisType, i: number) => {
                      return <div key={m.combined}>{m?.combined}</div>;
                    }
                  )
                  : NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Body part:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.bodyPart?.length
                  ? formValues?.bodyPart?.map((b: BodyPart, i: number) => {
                    return <div key={b.BodyPartName}>{b?.BodyPartName}</div>;
                  })
                  : NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Surgical:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getSurgicalFormattedNames(formValues?.surgical)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Employer:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.employerSelected?.label ||
                  formValues?.employerCompany ||
                  NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Job title:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.jobTitle || NOVALUE}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid container spacing={2}>
        <Box height="40px"></Box>
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: '15px' }}>
        <Typography
          variant="h6"
          style={{
            color:
              formErrors?.sections.includes(SectionType.Additional) ||
                formErrors?.sections.includes(SectionType.Authorization)
                ? styleVars.colorError
                : styleVars.colorGreen,
            fontSize: styleVars.fontSizeXxlarge,
            fontWeight: 500,
          }}
        >
          Additional info
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={2}>
          <Box height="24px"></Box>
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                <div className={classes.forceMaxWidth}>
                  <Typography
                    variant="body1"
                    style={{
                      color: formErrors?.sections.includes(
                        SectionType.Authorization
                      )
                        ? styleVars.colorError
                        : styleVars.colorGreen,
                      fontSize: styleVars.fontSizeNormal,
                      fontWeight: 500,
                    }}
                  >
                    AUTHORIZATIONS
                  </Typography>
                </div>
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    navigate('/referral/authorization');
                  }}
                >
                  Edit{' '}
                </Link>
                {formErrors?.sections.includes('authorization') && (
                  <Typography
                    component="span"
                    variant="body2"
                    className="missing-info-text"
                  >
                    {'  '}(Info Missing)
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {/* Parsing to simplified dates necessary */}
              <TableCell size="small" className={classes.leftCell}>
                Script date:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formatDate(formValues?.authScriptDate)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Authorized visits:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formatVisitText(
                  formValues?.authAuthorizedVisits,
                  formValues?.authVisitTimes,
                  formValues?.authVisitWeeks
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Authorized by:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.authAuthorizedBy || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Authorization number:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.authAuthorizationNumber || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Add'l injury date:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formatDate(formValues?.authAdditionalInjuryDate) || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Return to Dr. date:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formatDate(formValues?.authReturnToDoctorDate)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={9}></Grid>
      <Grid item xs={3}>
        <Grid container spacing={2}>
          <Box height="24px"></Box>
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.cell}>
                <div className={classes.forceMaxWidth}>
                  <Typography
                    variant="body1"
                    style={{
                      color: formErrors?.sections.includes(
                        SectionType.Stakeholder
                      )
                        ? styleVars.colorError
                        : styleVars.colorGreen,
                      fontSize: styleVars.fontSizeNormal,
                      fontWeight: 500,
                    }}
                  >
                    STAKEHOLDERS
                  </Typography>
                </div>
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    navigate('/referral/additionalInfo');
                  }}
                >
                  Edit
                </Link>
                {formErrors?.sections.includes(SectionType.Stakeholder) && (
                  <Typography
                    component="span"
                    variant="body2"
                    className="missing-info-text"
                  >
                    {'  '}(Info Missing)
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Adjuster:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {!!formValues?.selectAdjuster
                  ? formValues?.selectAdjuster?.label || NOVALUE
                  : formValues?.adjusterFirstName &&
                    formValues?.adjusterLastName &&
                    formValues.adjusterCompany
                    ? `${formValues?.adjusterFirstName || ''} ${formValues?.adjusterLastName || ''
                    } - ${formValues?.adjusterCompany || ''}`
                    : NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Nurse/Case manager:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {!!formValues?.selectCaseManager
                  ? formValues?.selectCaseManager?.label || NOVALUE
                  : formValues?.caseManagerFirstName &&
                    formValues?.caseManagerLastName &&
                    formValues.caseManagerCompany
                    ? `${formValues?.caseManagerFirstName || ''} ${formValues?.caseManagerLastName || ''
                    } - ${formValues?.caseManagerCompany || ''}`
                    : NOVALUE}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={9}></Grid>
      <Grid item xs={3}>
        <Grid container spacing={2}>
          <Box height="24px"></Box>
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.cell}>
                <div className={classes.forceMaxWidth}>
                  <Typography
                    variant="body1"
                    style={{
                      color: formErrors?.sections.includes(
                        SectionType.PatientOptional
                      )
                        ? styleVars.colorError
                        : styleVars.colorGreen,
                      fontSize: styleVars.fontSizeNormal,
                      fontWeight: 500,
                    }}
                  >
                    PATIENT
                  </Typography>
                </div>
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    navigate('/referral/additionalInfo');
                  }}
                >
                  Edit
                </Link>
                {formErrors?.sections.includes(SectionType.PatientOptional) && (
                  <Typography
                    component="span"
                    variant="body2"
                    className="missing-info-text"
                  >
                    {'  '}(Info Missing)
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Preferred name:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.patientPreferredName || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Middle name:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.patientMiddleName || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Suffix:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getSelectNameProperty(
                  formValues?.patientSuffix,
                  patientNameSuffixChoices
                ) || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Address:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formatAddress(
                  formValues?.patientAddress,
                  formValues?.patientCity,
                  formValues?.patientState,
                  formValues?.patientZip
                )}
                {/* {formValues?.patientAddress || NOVALUE} */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Marital status:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getSelectNameProperty(
                  formValues?.patientMaritalStatus,
                  maritalStatusChoices
                ) || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Race:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getSelectNameProperty(formValues?.patientRace, raceChoices) ||
                  NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Ethnicity:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getSelectNameProperty(
                  formValues?.patientEthnicity,
                  ethnicityChoices
                ) || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Emergency contact:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.patientEmergencyContactName || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Relationship:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.patientEmergencyContactRelationship || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Phone:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.patientEmergencyContactPhoneNumber || NOVALUE}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={9}></Grid>
      <Grid item xs={3}>
        <Grid container spacing={2}>
          <Box height="40px"></Box>
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.cell}>
                <div className={classes.forceMaxWidth}>
                  <Typography
                    variant="h6"
                    style={{
                      color: formErrors?.sections.includes('additionalInfo')
                        ? styleVars.colorError
                        : styleVars.colorGreen,
                      fontSize: styleVars.fontSizeNormal,
                      fontWeight: 500,
                    }}
                  >
                    OTHER
                  </Typography>
                </div>
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    navigate('/referral/additionalInfo');
                  }}
                >
                  Edit
                </Link>
                {formErrors?.sections.includes('additionalInfo') && (
                  <Typography
                    component="span"
                    variant="body2"
                    className="missing-info-text"
                  >
                    {'  '}(Info Missing)
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Preferred clinic:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.patientPreferredClinic || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Preferred therapist:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.patientPreferredClinician || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Other info/notes:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formValues?.patientOtherInfo || NOVALUE}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container spacing={2}>
          <Grid container spacing={2}>
            <Box height="60px" width="805px"></Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <DialogContainer
                hideCloseButton={true}
                title={(() => {
                  if (shownDialog === DialogOptions.ServerError) {
                    return 'Sorry... something went wrong';
                  } else if (shownDialog === DialogOptions.UploadWarning) {
                    return 'Please be sure to upload your documents';
                  } else {
                    return undefined;
                  }
                })()}
                classes={(() => {
                  if (shownDialog === DialogOptions.ValidationError) {
                    return 'submit-validation-dialog';
                  } else if (shownDialog === DialogOptions.ServerError) {
                    return 'submit-error-dialog';
                  } else if (shownDialog === DialogOptions.UploadWarning) {
                    return 'submit-upload-warning-dialog';
                  } else {
                    return 'submit-error-dialog';
                  }
                })()}
                onDialogClose={(toggleDialog: () => void) => {
                  if(submitOnDialogClose) {
                    setSubmitOnDialogClose(false);
                    submitReferralForm(toggleDialog);
                  }
                }}
                dialogContent={({
                  toggleDialog,
                }: {
                  toggleDialog: () => void;
                }) => {
                  if (shownDialog === DialogOptions.ValidationError) {
                    return (
                      <div className="submit-validation-dialog">
                        <Icon
                          icon={icError}
                          size={64}
                          style={{ color: '#B71B1D' }}
                        />
                        <h2>Oops! Some info is missing</h2>
                        <Typography component="p" variant="body2">
                          Please click <strong>edit</strong> wherever you see
                          "Info missing" on this page
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            toggleDialog();
                          }}
                        >
                          <Typography variant="button">OK</Typography>
                        </Button>
                      </div>
                    );
                  } else if (shownDialog === DialogOptions.ServerError) {
                    return (
                      <div className="submit-error-dialog">
                        <p>
                          Please wait a few minutes and then click "Submit"
                          again.
                        </p>
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            toggleDialog();
                          }}
                        >
                          <Typography variant="button">OK</Typography>
                        </Button>
                      </div>
                    );
                  } else if (shownDialog === DialogOptions.UploadWarning) {
                    return (
                      <>
                        <p>
                          Documentation such as prescriptions, FROIs, job
                          descriptions, and doctor's notes will help ensure
                          speedy placement for your patient.
                        </p>
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            setShownDialog(DialogOptions.UploadManagement);
                          }}
                        >
                          <Typography variant="button">Upload</Typography>
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            setSubmitOnDialogClose(true);
                            toggleDialog();
                          }}
                        >
                          <Typography variant="button">
                            Nothing to upload
                          </Typography>
                        </Button>
                      </>
                    );
                  } else if (shownDialog === DialogOptions.UploadManagement) {
                    return <UploadDialog toggleDialog={toggleDialog} />;
                  } else {
                    return <></>;
                  }
                }}
                dialogTrigger={({ toggleDialog }) => (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={(e: any) => onSubmitClick(e, toggleDialog)}
                      disabled={submitting}
                    >
                      <Typography variant="button">Submit</Typography>
                    </Button>
                  </Grid>
                )}
              />
              <DialogContainer
                hideCloseButton
                classes="cancel-dialog"
                dialogTrigger={({ toggleDialog }) => (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={() => {
                        toggleDialog();
                      }}
                    >
                      <Typography variant="button">Cancel</Typography>
                    </Button>
                  </Grid>
                )}
                dialogContent={({ toggleDialog }) => (
                  <CancelDialog
                    toggleDialog={toggleDialog}
                    handleClearForm={() =>
                      clearForm(formValues, setFormValues, navigate)
                    }
                  />
                )}
              />

              <Dialog id="submit-wait-modal" open={submitting}
              >
                <Box 
                  id="submit-wait-modal-content" 
                  style={{ 
                    textAlign: 'center', 
                    backgroundColor: 'white', 
                    width: 600,
                    padding: 40,
                    boxSizing: 'border-box'
                  }}
                >
                  <LinearProgress style={{ height: 12 }}></LinearProgress>
                  <Typography 
                    id="submit-wait-modal-title" 
                    style={{ fontWeight: 'medium', fontSize: '24px', paddingTop: 40}}
                  >
                    Please wait while we process your referral
                  </Typography>
                </Box>
              </Dialog>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Box height="30px"></Box>
          </Grid>
          <Grid item xs={7}>
            <LinearProgress
              variant="determinate"
              value={100}
              color="secondary"
              style={{ width: '145px' }}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography
              variant="body1"
              style={{
                fontSize: styleVars.fontSizeLarge,
                fontWeight: 500,
                // Not a good permanent solution for shifting the text upwards
                lineHeight: '1px',
                whiteSpace: 'nowrap',
                paddingLeft: '20px',
              }}
            >
              (Step 5 of 5)
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReviewForm;
