const raceChoices = [
    {
      name: '\0',
      value: '',
    },
    {
      name: 'Declined to Provide',
      value: '0000-0',
    },
    {
      name: 'American Indian or Alaska Native',
      value: '1002-5',
    },
    {
      name: 'Asian',
      value: '2028-9',
    },
    {
      name: 'Black or African American',
      value: '2054-5',
    },
    {
      name: 'Native Hawaiian or Other Pacific Islander',
      value: '2076-8',
    },
    {
      name: 'White',
      value: '2106-3',
    },
    {
      name: 'Some Other Race',
      value: '2131-1',
    },
    {
      name: 'Race Unknown',
      value: '9999-9',
    },
  ];

export default raceChoices