const languageChoices = [
  {
    name: '\0',
    value: '',
  },
  { value: "English", name: "English" },
  { value: "Spanish", name: "Spanish" },
  { value: "Albanian", name: "Albanian" },
  { value: "Arabic", name: "Arabic" },
  { value: "Armenian", name: "Armenian" },
  { value: "ASL", name: "ASL" },
  { value: "Bengali", name: "Bengali" },
  { value: "Bosnian", name: "Bosnian" },
  { value: "Burmese", name: "Burmese" },
  { value: "Cantonese", name: "Cantonese" },
  { value: "Chinese", name: "Chinese" },
  { value: "French", name: "French" },
  { value: "German", name: "German" },
  { value: "Greek", name: "Greek" },
  { value: "Gujarati", name: "Gujarati" },
  { value: "Haitian Creole", name: "Haitian Creole" },
  { value: "Hebrew", name: "Hebrew" },
  { value: "Hindi", name: "Hindi" },
  { value: "Hmong", name: "Hmong" },
  { value: "Italian", name: "Italian" },
  { value: "Japanese", name: "Japanese" },
  { value: "Karen", name: "Karen" },
  { value: "Khmer", name: "Khmer" },
  { value: "Korean", name: "Korean" },
  { value: "Latin", name: "Latin" },
  { value: "Lithuanian", name: "Lithuanian" },
  { value: "Malay", name: "Malay" },
  { value: "Mandarin", name: "Mandarin" },
  { value: "Nepali", name: "Nepali" },
  { value: "Panjabi", name: "Panjabi" },
  { value: "Persian", name: "Persian" },
  { value: "Polish", name: "Polish" },
  { value: "Portuguese", name: "Portuguese" },
  { value: "Punjabi", name: "Punjabi" },
  { value: "Romanian", name: "Romanian" },
  { value: "Russian", name: "Russian" },
  { value: "Somali", name: "Somali" },
  { value: "Tagalog", name: "Tagalog" },
  { value: "Telugu", name: "Telugu" },
  { value: "Thai", name: "Thai" },
  { value: "Turkish", name: "Turkish" },
  { value: "Urdu", name: "Urdu" },
  { value: "Vietnamese", name: "Vietnamese" },
  { value: "Other", name: "Other" },
];
export default languageChoices;
