const ethnicityChoices = [
    {
      name: '\0',
      value: '',
    },
    {
      name: 'Declined to Provide',
      value: '0000-0',
    },
    {
      name: 'Hispanic or Latino',
      value: '2135-2',
    },
    {
      name: 'Ethnicity Unknown',
      value: '9999-9',
    },
    {
      name: 'Not Hispanic or Latino',
      value: '2186-5',
    },
  ];

export default ethnicityChoices