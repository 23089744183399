import React, { useState, useEffect } from 'react';
import MagicSearchInput from '../generic/MagicSearchInput';
import { Autocomplete } from 'formik-material-ui-lab';
import { Field, FormikTouched, FormikErrors } from 'formik';
import { ReferralFormSchema } from '../../../types/ReferralFormSchema';
import { ic_search as icSearch } from 'react-icons-kit/md';
import Icon from 'react-icons-kit';
import { toSearchString, filterOptions } from '../../../helpers/autocomplete';

type Employer = {
  // label: string;
  // id: string;
  Name: string;
  EmployerID: string;
  JobAnalysisPresent: string;
  SearchText: string;
};

const getStatusLabel = (status: string) => {
  if (status === 'noResults') {
    return 'No results found';
  } else if (status === 'searching') {
    return 'Searching...';
  } else {
    return 'Search';
  }
};

export const EmployerSelect = (props: {
  className?: string;
  name: string;
  disabled: boolean;
  touched: FormikTouched<{ [field: string]: any }>;
  errors: { [field: string]: any };
  setTouched: (
    fields: { [field: string]: any },
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
}) => {
  const [employers, setEmployers] = useState([]);
  const [filteredEmployers, setFilteredEmployers] = useState<Employer[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    const firstThree = toSearchString(inputValue).substring(0, 3);
    if (firstThree.length < 3) {
      setFilteredEmployers([]);
    } else if (firstThree.length === 3) {
      fetchEmployers(firstThree);
    }
  }, [inputValue]);

  useEffect(() => {
    if (toSearchString(inputValue).length > 3 && employers?.length > 0) {
      setStatus('searching');
      const timeoutId = setTimeout(() => {
        setStatus('noResults');
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [inputValue, employers?.length]);

  // filter the employers options based on the input
  useEffect(() => {
    const clean = toSearchString(inputValue);
    if (clean.length >= 3) {
      const filtered = filterOptions<Employer>(employers, clean);
      setFilteredEmployers(filtered);
    }
  }, [inputValue, employers]);

  const fetchEmployers = (value: string) => {
    if (value?.length === 3) {
      setStatus('searching');
      fetch(
        `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/employers/${value}`,
        {
          headers: { accept: 'application/json' },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(JSON.stringify(response.status));
          }
          return response.json();
        })
        .then((data) => {
          // artificial slow request
          // setTimeout(() => {
          setStatus(data.length > 0 ? 'idle' : 'noResults');
          setEmployers(
            data.map((d: Employer) => {
              return {
                label: d.Name,
                id: d.EmployerID,
                ...d,
              };
            })
          );
          // }, 7000);
        })
        .catch((error) => console.log('Error:', error));
    }
  };

  return (
    <Field
      name={props.name}
      component={Autocomplete}
      id="employers-select-search"
      disabled={props.disabled}
      options={filteredEmployers}
      filterOptions={(options: [], state: any) => options}
      getOptionLabel={(option: { label: string }) => option.label}
      onChange={(e: MouseEvent, value: Employer[]) => {
        props.setFieldValue(props.name, value);
      }}
      onBlur={() => {
        const touchedFields = { ...props.touched };
        touchedFields[props.name] = true;
        props.setTouched(touchedFields);
      }}
      noOptionsText={getStatusLabel(status)}
      renderOption={(option: { label: string }) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {option.label}
        </div>
      )}
      renderInput={(params: any) => {
        const errorProps =
          props.errors?.[props.name] && props.touched?.[props.name]
            ? {
                error: true,
                helperText: 'Required Field',
              }
            : {};
        return (
          <MagicSearchInput
            style={{ marginTop: '4px' }}
            {...params}
            label="Employer"
            variant="outlined"
            helperText="Tip: Type any portion of the employer name to search"
            onChange={(e: { target: HTMLInputElement }) =>
              setInputValue(e.target.value)
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Icon
                  icon={icSearch}
                  size={24}
                  style={{
                    color: 'gray',
                    position: 'relative',
                    left: !!props.disabled ? '26px' : '52px',
                  }}
                />
              ),
            }}
            {...errorProps}
          />
        );
      }}
    />
  );
};
