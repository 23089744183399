import React, { useState, useEffect } from 'react';
import MagicSearchInput from '../generic/MagicSearchInput';
import { Autocomplete } from 'formik-material-ui-lab';
import { Field, FormikTouched, FormikErrors } from 'formik';
import { ReferralFormSchema } from '../../../types/ReferralFormSchema';
import { ic_search as icSearch } from 'react-icons-kit/md';
import Icon from 'react-icons-kit';
import { toSearchString, filterOptions } from '../../../helpers/autocomplete';

type CaseManager = {
  FirstName: string;
  LastName: string;
  Company: string;
  SalesforceId: string;
  SearchText: string;
};

const getStatusLabel = (status: string) => {
  if (status === 'noResults') {
    return 'No results found';
  } else if (status === 'searching') {
    return 'Searching...';
  } else {
    return 'Search';
  }
};

export const CaseManagerSelect = (props: {
  name: string;
  disabled: boolean;
  touched: FormikTouched<{ [field: string]: any }>;
  errors: { [field: string]: any };
  setTouched: (
    fields: { [field: string]: any },
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
}) => {
  const [caseManagers, setCaseManagers] = useState([]);
  const [filteredCaseManagers, setFilteredCaseManagers] = useState<
    CaseManager[]
  >([]);
  const [inputValue, setInputValue] = useState('');
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    const firstThree = toSearchString(inputValue).substring(0, 3);
    if (firstThree.length < 3) {
      setFilteredCaseManagers([]);
    } else if (firstThree.length === 3) {
      fetchCaseManagers(firstThree);
    }
  }, [inputValue]);

  useEffect(() => {
    if (toSearchString(inputValue).length > 3 && caseManagers?.length > 0) {
      setStatus('searching');
      const timeoutId = setTimeout(() => {
        setStatus('noResults');
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [inputValue, caseManagers?.length]);

  // filter the case managers options based on the input
  useEffect(() => {
    const clean = toSearchString(inputValue);
    if (clean.length >= 3) {
      const filtered = filterOptions<CaseManager>(caseManagers, clean);
      setFilteredCaseManagers(filtered);
    }
  }, [inputValue, caseManagers]);

  const fetchCaseManagers = (value: string) => {
    if (value?.length === 3) {
      setStatus('searching');
      fetch(
        `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/casemanagers/${value}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(JSON.stringify(response.status));
          }
          return response.json();
        })
        .then((data) => {
          setStatus(data.length > 0 ? 'idle' : 'noResults');
          setCaseManagers(
            data.map((d: CaseManager) => ({
              label: `${d.FirstName || ''} ${d.LastName || ''} ${
                d.Company ? '-' : ''
              } ${d.Company || ''}`,
              id: d.SalesforceId,
              ...d,
            }))
          );
        })
        .catch((error) => console.log('Error:', error));
    }
  };

  return (
    <Field
      name={props.name}
      component={Autocomplete}
      id="case-manager-select-search"
      disabled={props.disabled}
      options={filteredCaseManagers}
      filterOptions={(options: [], state: any) => options}
      getOptionLabel={(option: any) => option.label}
      onChange={(event: any, value: any[]) => {
        props.setFieldValue(props.name, value);
      }}
      onBlur={() => {
        const touchedFields = { ...props.touched };
        touchedFields[props.name] = true;
        props.setTouched(touchedFields);
      }}
      noOptionsText={getStatusLabel(status)}
      renderOption={(option: any) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {option.label}
        </div>
      )}
      renderInput={(params: any) => {
        const errorProps =
          props.errors?.[props.name] && props.touched?.[props.name]
            ? {
                error: true,
                helperText: 'Required Field',
              }
            : {};
        return (
          <MagicSearchInput
            {...params}
            label="Nurse/Case manager"
            variant="outlined"
            helperText="Tip: Start typing first or last name to search"
            onChange={(e: { target: HTMLInputElement }) => {
              setInputValue(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Icon
                  icon={icSearch}
                  size={24}
                  style={{
                    color: 'gray',
                    position: 'relative',
                    left: !!props.disabled ? '26px' : '52px',
                  }}
                />
              ),
            }}
            {...errorProps}
          />
        );
      }}
    />
  );
};
