/** --------------------------------------------------------------------------------------------- */
/**  Global Styling Variables                                                                     */
/** --------------------------------------------------------------------------------------------- */

const styleVars = {
  // colors grabbed from inVision app not already present here
  colorThemeBlue: '#3570a1',
  colorCardBlue: 'rgba(11, 120, 208, 0.1)',

  // colors specified by Debbie (may duplicate existing colors)
  colorBlue: '#0B78D0',
  colorLightBlue: '#0B78D061',
  colorLighterBlue: '#0B78D026',
  colorLightestBlue: '#0B78D00F',
  colorGreen: '#6FA135',
  colorGray: '#000000DE',
  colorLightGray: '#0000008A',
  colorLighterGray: '#00000061',
  colorLightestGray: '#FAFAFA',
  colorWhite: '#FFFFFF',

  // colors from the old Bardavon Product UI Guide
  colorPrimary: '#1565C0',
  colorAccent: '#0B78D0',
  colorHighlight: '#6FA136',
  colorAppBar: '#F3F3F4',
  colorPrimaryText: 'rgba(0,0,0,0.87)',
  colorSecondaryText: '#757575',
  colorDivider: '#E6E7E8',
  colorBodyBackground: '#FAFAFA',

  // supplemental colors
  colorCardBackground: '#FFF',
  colorInputBackground: '#FFF',
  colorListBackground: '#f4f4f4',
  colorListStripes: '#fcfcfc',
  colorListActive: '#FAFAFA',
  colorListHover: '#EEE',
  colorActiveNavBg: '#4383CC',
  colorButtonBlue: '#358CFA',
  colorRowHighlight: '#F3F9EC',
  colorListHighlight: '#CFE5F9',
  colorAppBarAlt: '#B3D4FC',
  colorAppBarAccent: '#D6D6D7',
  colorValueBarBg: '#E7EFF8',
  colorPickerBg: '#F2F5FC',
  colorHistoryCardBg: '#F0F6FC',
  colorInputLine: '#DCDCDC',
  colorMediumText: '#646464',
  colorNavDivider: '#FFFFFF',
  colorAccent2: '#4A90E2',
  colorInfo: '#7ED321',
  colorWarning: '#F5A623',
  colorError: '#B71C1C',
  colorProgress: '#327BC8',
  colorTabText: '#808080',
  colorAppBarBorder: '#b4b4b4',
  colorDashboardBorder: '#AEAFB0',
  colorAsideBackground: 'rgba(111, 161, 54, .1)',
  colorLightGreyBorder: '#E0E0E0',
  colorLightGreyBackground: '#FAFAFA',
  colorLightGreyBackgroundEmptyState: '#F1F1F1',
  colorLightBlueBackground: '#CFE5F9',
  colorDarkerGreyBackground: '#959595',
  colorRequiredFieldRed: '#BA2E1C',

  //Named colors
  bardGreen: '#6FA136',
  bardBlue600: '#0878d0',
  bardRed800: '#BA3412',

  // font sizes
  fontSizeXxxsmall: '10px',
  fontSizeXxsmall: '11px',
  fontSizeXsmall: '12px',
  fontSizeSmall: '13px',
  fontSizeNormal: '14px',
  fontSizeLarge: '16px',
  fontSizeXlarge: '18px',
  fontSizeXxlarge: '20px',
  fontSizeXxxxlarge: '24px',
  fontSizeXxxlarge: '22px',

  // layout
  appbarHeight: '64px',
  appbarSidePadding: '24px',
  contentBottomPadding: '40px',
  contentSidePadding: '56px',
  contentTopPadding: '10px',
  drawerDesktopWidth: '250px',
  drawerLinkMinHeight: '48px',
  drawerMobileWidth: '64px',
  footerHeight: '48px',
  minAppWidth: '1024px',

  // miscellaneous
  disabledOpacity: '0.5',
  dockActionWidth: '20px',
  dockIconWidth: '60px',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  lineHeight: '24px',

  avatarBackgroundColors: {
    EVAL: 'rgba(111, 161, 54, 0.70)',
    RE: '#1b5e20',
    APPT: '#6fa136',
    WC: 'rgba(0, 0, 0, 0.38)',
    WC2: 'rgba(0, 0, 0, 0.38)',
    WC3: 'rgba(0, 0, 0, 0.38)',
    WC4: 'rgba(0, 0, 0, 0.38)',
    WC8: 'rgba(0, 0, 0, 0.38)',
    WCN: 'rgba(0, 0, 0, 0.38)',
    SPL: '#ffffff',
    PAT: '#3f51b5',
    WS: '#FFA300',
    AQ: '#0b78d0',
    EMP: 'rgba(0, 0, 0, 0.54)',
    FCE: '#000',
    TENS: '#607d8b',
  },
  avatarTextColors: {
    SPL: 'rgba(0, 0, 0, 0.87)',
  },
  contactCardColors: {
    person: '#6fa136',
    adjuster: '#607D8B',
    case: '#3f51b5',
    insurance: 'rgba(0, 0, 0, 0.38)',
    emergency: '#6fa136',
    physician: '#FFA800',
  },
};
export default styleVars;
