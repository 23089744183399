const relationshipChoices = [
    {
      name: '\0',
      value: '',
    },
    {
      name: 'Spouse',
      value: 'Spouse',
    },
    {
      name: 'Parent',
      value: 'Parent',
    },
    {
      name: 'Significant other',
      value: 'Significant other',
    },
    {
      name: 'Friend',
      value: 'Friend',
    },
  ];

export default relationshipChoices