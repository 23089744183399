import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Print from './components/pages/Print';
import Home from './components/pages/Home';
import ReferralForm from './components/pages/ReferralForm';
import { CustomLayout } from './components/CustomLayout';

const NotFound = () => <h1>Not Found</h1>;

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const router = createBrowserRouter([
  {
    path: '/',
    element: <CustomLayout />,
    children: [
      { index: true, element: <Home /> },
      { index: true, path: '/referral/print/*', element: <Print /> },
      { index: true, path: '/referral/*', element: <ReferralForm /> },
      { path: '*', element: <NotFound /> },
    ],
  },
]);
ReactDOM.render(<RouterProvider router={router} />, MOUNT_NODE);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
