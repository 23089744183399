import { File, UploadFileType } from "../types/ReferralFormSchema";

export const uploadFile = (file: File & UploadFileType & Blob) => {
  const formData = new FormData()
  formData.append('file', file)

  return fetch(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/fileupload`,
    { 
    method: 'POST', 
    body: formData,
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(JSON.stringify(response.status));
    }
    return response.json();
  })
  .then((data) => data.uploadRequestId)
  .catch((error) => {
    console.error(error);
    return null;
  })
}