import { ReferralFormSchema } from "../types/ReferralFormSchema";
import { getPrefillFieldsFromUser } from "./prefill";

export const clearForm = (formValues: ReferralFormSchema, setFormValues: (values: ReferralFormSchema) => void, navigate: (url: string) => void, addingAnother?: boolean) => {
    let prefillFields = {};
    let newFormValues = null;
    if (!!formValues?.userRememberMe || !!addingAnother) {
      newFormValues = {
        formStep: 2,
        userFirstName: formValues?.userFirstName,
        userLastName: formValues?.userLastName,
        userEmail: formValues?.userEmail,
        userCompany: formValues?.userCompany,
        customUserInfo: !!formValues?.customUserInfo,
        userPhonePreferred: !!formValues?.userPhonePreferred,
        userEmailPreferred: !!formValues?.userEmailPreferred,
        userPhoneNumber: formValues?.userPhoneNumber,
        userPhoneType: formValues?.userPhoneType,
        userPhoneExtension: formValues?.userPhoneExtension,
        userType: formValues?.userType,
        userRememberMe: formValues?.userRememberMe,
        userAdjuster: formValues?.userAdjuster,
        userReferringPhysician:
          formValues?.userReferringPhysician,
        userCaseManager: formValues?.userCaseManager,
        userNewOrExisting: formValues?.userNewOrExisting,
        userRoleInCase: formValues?.userRoleInCase,
      };
      prefillFields = getPrefillFieldsFromUser(newFormValues, newFormValues.formStep);
    } else {
      newFormValues = {}
    }
    setFormValues({ ...newFormValues, ...prefillFields});
    navigate(!!addingAnother ? '/referral/requiredInfo' : '/referral/user')
    return 
}