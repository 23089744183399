import React, { useState, useEffect } from 'react';
import MagicSearchInput from '../generic/MagicSearchInput';
import { Autocomplete } from 'formik-material-ui-lab';
import { Field, FormikTouched, FormikErrors } from 'formik';
import { ReferralFormSchema } from '../../../types/ReferralFormSchema';
import { ic_search as icSearch } from 'react-icons-kit/md';
import Icon from 'react-icons-kit';
import { toSearchString, filterOptions } from '../../../helpers/autocomplete';

type Physician = {
  Fax: string;
  NPI: string;
  Phone: string;
  LastName: string;
  FirstName: string;
  SalesforceId: string;
  bNotesReferringId: string;
  SearchText: string;
};

const getStatusLabel = (status: string) => {
  if (status === 'noResults') {
    return 'No results found';
  } else if (status === 'searching') {
    return 'Searching...';
  } else {
    return 'Search';
  }
};

export const ReferringPhysicianSelect = (props: {
  name: string;
  label: string;
  required: boolean;
  disabled: boolean;
  touched: FormikTouched<{ [field: string]: any }>;
  errors: { [field: string]: any };
  setTouched: (
    fields: { [field: string]: any },
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
}) => {
  const [physicians, setPhysicians] = useState([]);
  const [filteredPhysicians, setFilteredPhysicians] = useState<Physician[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    const firstThree = toSearchString(inputValue).substring(0, 3);
    if (firstThree.length < 3) {
      setFilteredPhysicians([]);
    } else if (firstThree.length === 3) {
      fetchPhysicians(firstThree);
    }
  }, [inputValue]);

  useEffect(() => {
    if (toSearchString(inputValue).length > 3 && physicians?.length > 0) {
      setStatus('searching');
      const timeoutId = setTimeout(() => {
        setStatus('noResults');
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [inputValue, physicians?.length]);

  // filter the adjusters options based on the input
  useEffect(() => {
    const clean = toSearchString(inputValue);
    if (clean.length >= 3) {
      const filtered = filterOptions<Physician>(physicians, clean);
      setFilteredPhysicians(filtered);
    }
  }, [inputValue, physicians]);

  const fetchPhysicians = (value: string) => {
    if (value?.length === 3) {
      setStatus('searching');
      fetch(
        `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/physicians/${value}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(JSON.stringify(response.status));
          }
          return response.json();
        })
        .then((data) => {
          setStatus(data.length > 0 ? 'idle' : 'noResults');
          setPhysicians(
            data.map((d: Physician) => {
              let contactText = '';
              if (d?.Phone || d?.Fax) {
                if (d?.Phone) {
                  contactText = 'ph';
                } else if (d?.Fax) contactText = 'fax';
              } else {
                contactText = '';
              }
              return {
                label: `${d.FirstName || ''} ${d.LastName || ''} ${
                  d.Phone ? '-' : ''
                } ${contactText} ${d.Phone ? d.Phone || '' : d.Fax || ''}`,
                id: d.SalesforceId,
                ...d,
              };
            })
          );
        })
        .catch((error) => console.log('Error:', error));
    }
  };

  return (
    <Field
      name={props.name}
      component={Autocomplete}
      id="referring-doctor-search"
      disabled={props.disabled}
      options={filteredPhysicians}
      filterOptions={(options: [], state: any) => options}
      getOptionLabel={(option: { label: string }) => option.label}
      onChange={(e: MouseEvent, value: Physician[]) => {
        props.setFieldValue(props.name, value);
      }}
      onBlur={() => {
        const touchedFields = { ...props.touched };
        touchedFields[props.name] = true;
        props.setTouched(touchedFields);
      }}
      noOptionsText={getStatusLabel(status)}
      renderOption={(option: { label: string }) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {option.label}
        </div>
      )}
      renderInput={(params: any) => {
        const errorProps =
          props.errors?.[props.name] && props.touched?.[props.name]
            ? {
                error: true,
                helperText: 'Required Field',
              }
            : {};
        return (
          <MagicSearchInput
            {...params}
            label={props.label}
            required={props.required}
            variant="outlined"
            helperText="Tip: Start typing first or last name to search"
            onChange={(e: { target: HTMLInputElement }) =>
              setInputValue(e.target.value)
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Icon
                  icon={icSearch}
                  size={24}
                  style={{
                    color: 'gray',
                    position: 'relative',
                    left: !!props.disabled ? '26px' : '52px',
                  }}
                />
              ),
            }}
            {...errorProps}
          />
        );
      }}
    />
  );
};
