import * as Yup from 'yup';
import { phoneExtensionValidation, phoneNumberValidationTest } from '../helpers/validationHelpers';
import { ERROR_REQUIRED_TXT } from '../constants/textConstants';

// TODO: These validations appear to be duplicates of others. We ran into a problem with validation 
//       on the other form and updated there without knowing that we also need to update here. These
//       really need to be consolidated so that all field validations are defined only once.

export const StakeholderValidation = Yup.object().shape({
  adjusterFirstName: Yup.string().when('customAdjusterInfo', {
    is: true,
    then: Yup.string().required(ERROR_REQUIRED_TXT),
  }),
  adjusterLastName: Yup.string().when('customAdjusterInfo', {
    is: true,
    then: Yup.string().required(ERROR_REQUIRED_TXT),
  }),

  adjusterCompany: Yup.string().when('customAdjusterInfo', {
    is: true,
    then: Yup.string().required(ERROR_REQUIRED_TXT),
  }),
  adjusterPhoneType: Yup.string(),

  adjusterPhoneExtension: phoneExtensionValidation,
  caseManagerFirstName: Yup.string().when('customCaseManagerInfo', {
    is: true,
    then: Yup.string().required(ERROR_REQUIRED_TXT),
  }),

  caseManagerLastName: Yup.string().when('customCaseManagerInfo', {
    is: true,
    then: Yup.string().required(ERROR_REQUIRED_TXT),
  }),

  caseManagerCompany: Yup.string().when('customCaseManagerInfo', {
    is: true,
    then: Yup.string().required(ERROR_REQUIRED_TXT),
  }),

  caseManagerPhoneType: Yup.string(),
  adjusterEmail: Yup.string().when('customAdjusterInfo', {
    is: true,
    then: Yup.string().when('adjusterEmailPreferred', {
      is: true,
      then: Yup.string()
        .email('invalid email')
        .required('Required when preferred'),
      otherwise: Yup.string().when('adjusterPhoneNumber', {
        is: (exist) => !exist,
        then: Yup.string()
          .email('invalid email')
          .required('Phone or email required'),
      }),
    }),
  }),
  adjusterPhoneNumber: Yup.string().when('customAdjusterInfo', {
    is: true,
    then: Yup.string().when('adjusterPhonePreferred', {
      is: true,
      then: Yup.string()
        .test('len', 'Invalid phone', phoneNumberValidationTest)
        .required('Required when preferred'),
      otherwise: Yup.string().when('adjusterEmail', {
        is: (exist) => !exist,
        then: Yup.string()
          .test('len', 'Invalid phone', phoneNumberValidationTest)
          .required('Phone or email required'),
      }),
    }),
  }),
  caseManagerEmail: Yup.string().when('customCaseManagerInfo', {
    is: true,
    then: Yup.string().when('caseManagerEmailPreferred', {
      is: true,
      then: Yup.string()
        .email('invalid email')
        .required('Required when preferred'),
      otherwise: Yup.string().when('caseManagerPhoneNumber', {
        is: (exist) => !exist,
        then: Yup.string()
          .email('invalid email')
          .required('Phone or email required'),
      }),
    }),
  }),
  caseManagerPhoneNumber: Yup.string().when('customCaseManagerInfo', {
    is: true,
    then: Yup.string().when('caseManagerPhonePreferred', {
      is: true,
      then: Yup.string()
        .test('len', 'Invalid phone', phoneNumberValidationTest)
        .required('Required when preferred'),
      otherwise: Yup.string().when('caseManagerEmail', {
        is: (exist) => !exist,
        then: Yup.string()
          .test('len', 'Invalid phone', phoneNumberValidationTest)
          .required('Phone or email required'),
      }),
    }),
  }),
  adjusterEmailPreferred: Yup.boolean(),
  adjusterPhonePreferred: Yup.boolean(),
  caseManagerEmailPreferred: Yup.boolean(),
  caseManagerPhonePreferred: Yup.boolean(),
  caseMangerPhoneExtension: phoneExtensionValidation,
});

export const OptionalPatientValidation = Yup.object().shape({
  patientPreferredName: Yup.string(),
  patientMiddleName: Yup.string(),
  patientSuffix: Yup.string(),
  patientAddress: Yup.string(),
  patientCity: Yup.string(),
  patientState: Yup.string(),
  patientMaritalStatus: Yup.string(),
  patientRace: Yup.string(),
  patientEthnicity: Yup.string(),
  patientPreferredLanguage: Yup.string(),
  patientEmergencyContactName: Yup.string(),
  patientEmergencyContactRelationship: Yup.string(),
  patientPhoneNumber: Yup.string(),
});

export const OtherValidation = Yup.object().shape({
  patientPreferredClinic: Yup.string().max(
    255,
    'Must be 255 characters or less'
  ),
  patientPreferredClinician: Yup.string().max(
    255,
    'Must be 255 characters or less'
  ),
  patientOtherInfo: Yup.string().max(1000, 'Must be 1000 characters or less'),
});
