import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Box,
  Grid,
  Typography,
  LinearProgress,
} from '@material-ui/core';

import { Field, Form, ErrorMessage, useFormik, FormikProvider } from 'formik';
import { FormContext } from '../../contexts/FormContext';
import { MagicTextField } from '../inputs/generic/MagicTextField';
import { MagicDateField } from '../inputs/generic/MagicDateField';
import { MagicSelect } from '../inputs/generic/MagicSelect';

import { ERROR_NO_FUTURE_DATES } from '../../constants/textConstants';
import { clearForm } from '../../helpers/clearForm';
import authorizationFormValidation from '../../yup/AuthorizationFormValidation';
import styleVars from '../../styleVars';
import {
  AuthorizationFormSchema,
  ReferralFormSchema,
} from '../../types/ReferralFormSchema';
import { CancelDialog } from '../dialogs/CancelDialog';
import DialogContainer from '../dialogs/DialogContainer';

const AuthorizationForm = () => {
  const navigate = useNavigate();

  const { formValues, setFormValues } = useContext(FormContext);
  const formik = useFormik({
    initialValues: {
      formStep:
        Number(formValues?.formStep) < 3 ? 3 : formValues?.formStep || 3,
      customAdjusterInfo: formValues?.customAdjusterInfo,
      customCaseManagerInfo: formValues?.customCaseManagerInfo,
      authScriptDate: formValues?.authScriptDate || null,
      authAuthorizedVisits: formValues?.authAuthorizedVisits,
      authVisitTimes: formValues?.authVisitTimes,
      authVisitWeeks: formValues?.authVisitWeeks,
      authAuthorizedBy: formValues?.authAuthorizedBy || '',
      authAdditionalInjuryDate: formValues?.authAdditionalInjuryDate || null,
      authReturnToDoctorDate: formValues?.authReturnToDoctorDate || null,
      authAuthorizationNumber: formValues?.authAuthorizationNumber,
    } as AuthorizationFormSchema,

    validationSchema: authorizationFormValidation,
    onSubmit: (values) => {
      formik.setSubmitting(false);
      // spread new properties over old values while leaving other pages alone
      setFormValues({ ...formValues, ...values });
    },
    // validateOnChange: true,
    // validateOnMount: true,
    validateOnBlur: true,
  });
  const {
    submitForm,
    values,
    validateForm,
    setFieldValue,
    isValid,
    setTouched,
  } = formik;
  const refValues = useRef(values);
  refValues.current = values;

  useEffect(() => {
    window.scrollTo(0, 0);
    validateForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.formStep === 5) {
      const touchAll = Object.keys(values).reduce((acc: any, key: string) => {
        acc[key] = true;
        return acc;
      }, {});
      setTouched(touchAll);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Exercise caution when modifying this as updating context in an unmount can cause infinite updates
  useEffect(() => {
    // When the component unmounts, update the context values with the current form values
    return () => {
      setFormValues((prevValues: ReferralFormSchema) => {
        return {
          ...prevValues,
          ...refValues.current,
        };
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Number(formValues?.formStep) < 3) {
      setFormValues({ ...formValues, formStep: 3 });
    }
  }, [formValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate>
          <Grid item xs={12}>
            <Box style={{ width: '925px' }}></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ color: '#3470A1' }}>
              Great. That's enough for us to get started.
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Box height="28px"></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{
                fontSize: styleVars.fontSizeLarge,
                fontWeight: 500,
                color: 'gray',
              }}
            >
              We can schedule your patient more quickly if we have the info
              below.
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Box height="28px"></Box>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Field
                component={MagicDateField}
                id="auth-script-date"
                label="Script date"
                name="authScriptDate"
                InputLabelProps={{
                  shrink: !!values.authScriptDate,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={MagicTextField}
                name="authAuthorizedVisits"
                type="text"
                label="Authorized visits"
                helperText={<ErrorMessage name="authAuthorizedVisits" />}
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                component={MagicTextField}
                name="authVisitTimes"
                type="text"
                label="# times"
                helperText={<ErrorMessage name="authVisitTimes" />}
              />
            </Grid>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ whiteSpace: 'nowrap', color: 'gray' }}>
                /week for
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Field
                component={MagicTextField}
                name="authVisitWeeks"
                type="text"
                label="weeks"
                helperText={<ErrorMessage name="authVisitWeeks" />}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="authAuthorizedBy"
                label="Authorized by"
                id="authorized-by-select"
                component={MagicSelect}
                items={[
                  {
                    name: '\0',
                    value: '',
                  },
                  { name: 'Adjuster', value: 'Adjuster' },
                  { name: 'Nurse/Case manager', value: 'Case Manager' },
                  { name: 'Other', value: 'Other' },
                  { name: 'Other network', value: 'Other Network' },
                  { name: 'Not authorized', value: 'Not Authorized' },
                ]}
                onChange={(e: { target: HTMLInputElement }) => {
                  setFieldValue('authAuthorizedBy', e.target.value);
                }}
                helperText={<ErrorMessage name="authAuthorizedBy" />}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={MagicTextField}
                name="authAuthorizationNumber"
                type="text"
                label="Authorization number"
                helperText={<ErrorMessage name="authAuthorizationNumber" />}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={MagicDateField}
                disableFuture
                id="auth-additional-injury-date"
                label="Add'l injury date"
                name="authAdditionalInjuryDate"
                maxDateMessage={ERROR_NO_FUTURE_DATES}
                InputLabelProps={{
                  shrink: !!values.authAdditionalInjuryDate,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={MagicDateField}
                id="auth-return-to-doctor-date"
                label="Return to Dr date"
                name="authReturnToDoctorDate"
                InputLabelProps={{
                  shrink: !!values.authReturnToDoctorDate,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Box height="30px"></Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => {
                      submitForm();
                      if (isValid) {
                        navigate('/referral/additionalInfo');
                      }
                    }}
                  >
                    <Typography variant="button">Next</Typography>
                  </Button>
                </Grid>
                <DialogContainer
                  hideCloseButton
                  classes="cancel-dialog"
                  dialogTrigger={({
                    toggleDialog,
                  }: {
                    toggleDialog: () => void;
                  }) => (
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onClick={() => {
                          toggleDialog();
                        }}
                      >
                        <Typography variant="button">Cancel</Typography>
                      </Button>
                    </Grid>
                  )}
                  dialogContent={({ toggleDialog }) => (
                    <CancelDialog
                      toggleDialog={toggleDialog}
                      handleClearForm={() =>
                        clearForm(formValues, setFormValues, navigate)
                      }
                    />
                  )}
                />
                <Grid item>
                  <Button
                    color="primary"
                    size="medium"
                    onClick={() => navigate('/referral/requiredInfo')}
                    style={{ textTransform: 'none' }}
                  >
                    <Typography>Back</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    size="medium"
                    onClick={() => {
                      submitForm();
                      if (isValid) {
                        navigate('/referral/review');
                      }
                    }}
                    style={{ textTransform: 'none' }}
                  >
                    <Typography>Skip to last step</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Box height="30px"></Box>
            </Grid>
            <Grid item xs={2}>
              <LinearProgress
                variant="determinate"
                value={60}
                color="secondary"
              />
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="body1"
                style={{
                  fontSize: styleVars.fontSizeLarge,
                  fontWeight: 500,
                  // Not a good permanent solution for shifting the text upwards
                  lineHeight: '1px',
                  width: '145px',
                }}
              >
                (Step 3 of 5)
              </Typography>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AuthorizationForm;
