import {
  createContext,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';
import { ReferralFormSchema } from '../types/ReferralFormSchema';

interface FormContextType {
  formValues: ReferralFormSchema;
  setFormValues: Dispatch<SetStateAction<ReferralFormSchema>>;
}

interface Props {
  children?: ReactNode;
}

export const FormContext = createContext({} as FormContextType);

export const FormProvider = ({ children }: Props) => {
  let parsedData = {};

  const storedData = localStorage.getItem('userData');

  if (storedData) {
    try {
      parsedData = JSON.parse(storedData);
    } catch (error) {
      console.error('Error parsing stored user data', error);
    }
  }

  const [formValues, setFormValues] = useState(parsedData);

  const context = useMemo(
    () => ({ formValues, setFormValues }),
    [formValues, setFormValues]
  );

  return (
    <FormContext.Provider value={context}>{children}</FormContext.Provider>
  );
};
