import React from 'react';
import classnames from 'classnames';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useField } from 'formik';
import styleVars from '../../../styleVars';

const useStyles = makeStyles((theme) => ({
  unchecked: {
    color: theme.palette.grey[500], //#9E9E9E
    '& svg': {
      height: '24px',
      width: '24px',
    },
  },
  checked: { color: styleVars.colorGreen },
  label: {
    color: styleVars.colorPrimaryText,
    fontSize: styleVars.fontSizeNormal,
  },
}));

/* ---------------------------------------------------------------------------------------------- */
/*  Standard styled checkbox (no label)                                                           */
/* ---------------------------------------------------------------------------------------------- */

export const StandardCheckbox = (props: CheckboxProps) => {
  const classes = useStyles();
  return (
    <Checkbox
      classes={{ root: classes.unchecked, checked: classes.checked }}
      color="default"
      {...props}
    />
  );
};

/* ---------------------------------------------------------------------------------------------- */
/*  FormikCheckbox (checkbox component for use with Formik; no label)                             */
/* ---------------------------------------------------------------------------------------------- */

type SpecialCheckboxType = Required<Pick<CheckboxProps, 'name'>>;
type BaseCheckboxType = Omit<CheckboxProps, 'name'>;
type FormikCheckboxProps = BaseCheckboxType & SpecialCheckboxType;

export const FormikCheckbox = ({
  name,
  ...checkboxProps
}: FormikCheckboxProps) => {
  const [fieldProps] = useField<boolean>({ name });
  return (
    <StandardCheckbox
      {...checkboxProps}
      {...fieldProps}
      checked={fieldProps.value}
    />
  );
};

/* ---------------------------------------------------------------------------------------------- */
/*  CheckboxLabel (StandardCheckbox with a label on the right)                                    */
/* ---------------------------------------------------------------------------------------------- */

export type CheckboxLabelProps = {
  checked?: boolean;
  id?: string; // TODO should be required
  name?: string; // TODO can probably be removed
  onChange?: CheckboxProps['onChange'];
  labelText?: string | React.ReactElement; // TODO should be required
  extraRenderContent?: React.ReactNode;
  value?: string;
  labelTextClass?: string;
  controlLabelClassName?: string;
  disabled?: boolean;
};

export const CheckboxLabel = ({
  labelText,
  checked,
  onChange,
  name,
  labelTextClass,
  controlLabelClassName,
  ...restOfProps
}: CheckboxLabelProps) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      label={
        <Typography className={classnames(classes.label, labelTextClass)}>
          {labelText}
        </Typography>
      }
      control={
        <StandardCheckbox
          checked={checked}
          onChange={onChange}
          name={name}
          {...restOfProps}
        />
      }
      className={controlLabelClassName}
    />
  );
};

export const FormikCheckboxLabel = ({
  name,
  labelText,
  onChange: parentOnChange,
  controlLabelClassName,
  disabled,
  ...restOfProps
}: Omit<CheckboxLabelProps, 'name'> & {
  name: string;
  controlLabelClassName?: string;
}) => {
  const [{ value }, , { setValue }] = useField<boolean>(name);

  const handleOnChange: CheckboxProps['onChange'] = (event, checked) => {
    if (!disabled) {
      setValue(checked);
      if (parentOnChange) parentOnChange(event, checked);
    }
  };

  return (
    <CheckboxLabel
      labelText={labelText}
      name={name}
      onChange={handleOnChange}
      checked={value}
      controlLabelClassName={controlLabelClassName}
      {...restOfProps}
    />
  );
};
