const patientNameSuffixChoices = [
    {
      name: '\0',
      value: '',
    },
    {
      name: 'N/A',
      value: '',
    },
    {
      name: 'Sr.',
      value: 'sr',
    },
    {
      name: 'Jr.',
      value: 'jr',
    },
    {
      name: 'II',
      value: 'ii',
    },
    {
      name: 'III',
      value: 'iii',
    },
    {
      name: 'IV',
      value: 'iv',
    },
  ];

export default patientNameSuffixChoices