import MuiTextField from '@material-ui/core/TextField';
import { TextFieldProps, Box, makeStyles } from '@material-ui/core';
import styleVars from '../../../styleVars';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    padding: '3.5px 0px 3.5px 0px',
  },
  textarea: {
    '& .MuiInputBase-root': {
      backgroundColor: styleVars.colorLightestGray,
    },
    // '& .MuiAutocomplete-input': {
    //   padding: '8px 4px !important',
    // },
  },
  counts: {
    color: styleVars.colorSecondaryText,
    fontSize: styleVars.fontSizeXxxsmall,
    position: 'absolute',
    right: '9px',
    top: '3px',
    height: '7px',
    borderBottom: '3px solid white',
    padding: '0 4px',
  },
  input: {
    '.MuiOutlinedInput-root': {
      height: '56px',
    },
  },
});

// Used for Autocomplete renderInput
const MagicSearchInput = (props: TextFieldProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <MuiTextField
        {...props}
        className={classes.textarea}
        variant="outlined"
        fullWidth
        autoComplete="off"
        margin="dense"
      />
    </Box>
  );
};

export default MagicSearchInput;
