import { FC } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { TextField, TextFieldProps } from 'formik-material-ui';
import styleVars from '../../../styleVars';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  textarea: {
    '& .MuiInputBase-root': {
      backgroundColor: styleVars.colorLightestGray,
    },
  },
  countsContainer: {
    backgroundColor: '#ffffff',
    borderBottom: `1px solid ${styleVars.colorLightGray}`,
    overflow: 'visible',
    position: 'absolute',
    right: '9px',
    top: '7px',
    height: '3px',
  },
  counts: {
    color: styleVars.colorSecondaryText,
    fontSize: styleVars.fontSizeXxxsmall,
    margin: '0 5px',
    position: 'relative',
    bottom: '4px',
  },
  separator: {
    margin: '0 1px',
  },
});

export type MagicTextFieldProps = TextFieldProps & {
  maxCharacters?: number;
  showCharacterCount?: boolean;
  showMaxCharacters?: boolean;
};

export const MagicTextField: FC<MagicTextFieldProps> = ({
  maxCharacters = null,
  showCharacterCount = false,
  showMaxCharacters = false,
  ...props
}) => {
  const classes = useStyles();
  const characterCount = props.field?.value?.length || 0;
  return (
    <Box className={classes.container}>
      <TextField
        className={classes.textarea}
        fullWidth
        margin="dense"
        inputProps={{ maxLength: maxCharacters, 'data-lpignore': true }}
        variant="outlined"
        {...props}
      />
      {showCharacterCount || showMaxCharacters ? (
        <Box className={classes.countsContainer}>
          <Box className={classes.counts}>
            {showCharacterCount ? characterCount.toLocaleString() : null}
            {showCharacterCount &&
            showMaxCharacters &&
            maxCharacters &&
            maxCharacters > 0 ? (
              <span className={classes.separator}>/</span>
            ) : null}
            {showMaxCharacters && maxCharacters && maxCharacters > 0
              ? maxCharacters?.toLocaleString()
              : null}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
