import React, { FC } from 'react';
import { MenuItem, makeStyles } from '@material-ui/core';
import { TextField, TextFieldProps } from 'formik-material-ui';
import styleVars from '../../../styleVars';

const useStyles = makeStyles({
  textarea: {
    '& .MuiInputBase-root': {
      backgroundColor: styleVars.colorLightestGray,
    },
  },
});

export type MagicSelectItem = {
  name: string;
  value: string;
};

export type MagicSelectProps = TextFieldProps & {
  addBlankItem?: boolean;
  label?: string;
  name: string;
  items: MagicSelectItem[];
};

export const MagicSelect: FC<MagicSelectProps> = ({
  addBlankItem = false,
  label,
  items,
  ...props
}) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.textarea}
      select
      label={label}
      fullWidth
      margin="dense"
      variant="outlined"
      {...props}
    >
      {addBlankItem ? (
        <MenuItem key="blank-option" value="">
          &nbsp;
        </MenuItem>
      ) : null}
      {items.map((o: MagicSelectItem) => (
        <MenuItem key={o.value} value={o.value}>
          {o.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
