import React from 'react';
import { Grid, Box, AppBar, makeStyles } from '@material-ui/core';
import { Outlet, useNavigate } from 'react-router-dom';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

import AppIcon from './icons/AppIcon';
import styleVars from '../styleVars';

const theme = createTheme({
  palette: {
    primary: {
      // main: '#0C72B1',
      main: '#0B78D0',
    },
    secondary: {
      // main: '#71A034',
      main: '#6FA136',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100vh',
    // background: styleVars.colorBodyBackground,
  },
  appBar: {
    borderRadius: 0,
    paddingBottom: 0,
    background: styleVars.colorThemeBlue,
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1425px',
    alignItems: 'center',
  },
  item: {
    marginLeft: '-8px',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: 'white',
    display: 'block',
    textTransform: 'none',
  },
  box: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 0, 12, 0),
    maxWidth: '1425px',
    alignItems: 'center',
  },
  // Dynamically set minimum left margin based on screen size
  '@media (max-width: 1600px)': {
    box: {
      marginLeft: '80px',
      marginRight: '0px',
    },
  },
  '@media (max-width: 1450px)': {
    box: {
      marginLeft: '70px',
      marginRight: '0px',
    },
  },
  '@media (max-width: 1300px)': {
    box: {
      marginLeft: '65px',
      marginRight: '0px',
    },
  },
  '@media (max-width: 1250px)': {
    box: {
      marginLeft: '55px',
      marginRight: '0px',
    },
  },
  '@media (max-width: 1000px)': {
    box: {
      marginLeft: '48px',
      marginRight: '0px',
    },
  },
  '@media print': {
    appBar: {
      display: 'None',
    },
    box: {
      width: '8in',
      padding: '0 0.75in',
    },
  },
  
}));

export const CustomLayout = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Grid container className={classes.container}>
            <Box
              height="20px"
              sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
            ></Box>
          </Grid>
        </AppBar>
        <Box className={classes.box}>
          <Box
            mt={1}
            mb={1}
            pt={4}
            pb={2}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/referral/user');
            }}
          >
            <AppIcon />
          </Box>
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};
