/* This is used to prefill Adjuster/NCM/Physician fields based on the user's info */

import { ReferralFormSchema, UserType } from "../types/ReferralFormSchema";

export function getPrefillFieldsFromUser(values: ReferralFormSchema, formStep: number) {

  let prefillFields = {};

  if (
    values.userType === UserType.Adjuster &&
    Number(formStep) < 4
  ) {
    if (values.userNewOrExisting === 'new') {
      prefillFields = {
        customAdjusterInfo: true,
        adjusterFirstName: values?.userFirstName,
        adjusterLastName: values?.userLastName,
        adjusterEmail: values?.userEmail,
        adjusterCompany: values?.userCompany,
        adjusterPhonePreferred: !!values?.userPhonePreferred,
        adjusterEmailPreferred: !!values?.userEmailPreferred,
        adjusterPhoneNumber: values?.userPhoneNumber,
        adjusterPhoneType: values?.userPhoneType,
        adjusterPhoneExtension: values?.userPhoneExtension,
      };
    } else {
      values.userEmail = values?.userAdjuster?.Email
      prefillFields = {
        selectAdjuster: {
          ...values?.userAdjuster,
          label: `${values?.userAdjuster?.FirstName || ''} ${
            values?.userAdjuster?.LastName || ''
          } ${values?.userAdjuster?.Company ? '-' : ''} ${
            values?.userAdjuster?.Company || ''
          }`,
          id: values?.userAdjuster?.SalesforceId,
        },
      };
    }
  } else if (
    values.userType === UserType.CaseManager &&
    Number(formStep) < 4
  ) {
    if (values.userNewOrExisting === 'new') {
      prefillFields = {
        customCaseManagerInfo: true,
        caseManagerFirstName: values?.userFirstName,
        caseManagerLastName: values?.userLastName,
        caseManagerEmail: values?.userEmail,
        caseManagerCompany: values?.userCompany,
        caseManagerPhonePreferred: !!values?.userPhonePreferred,
        caseManagerEmailPreferred: !!values?.userEmailPreferred,
        caseManagerPhoneNumber: values?.userPhoneNumber,
        caseMangerPhoneType: values?.userPhoneType,
        caseManagerPhoneExtension: values?.userPhoneExtension,
      };
    } else {
      values.userEmail = values?.userCaseManager?.Email
      prefillFields = {
        selectCaseManager: {
          ...values?.userCaseManager,
          id: values?.userCaseManager?.SalesforceId,
          label: `${values?.userCaseManager?.FirstName || ''} ${
            values?.userCaseManager?.LastName || ''
          } ${values?.userCaseManager?.Company ? '-' : ''} ${
            values?.userCaseManager?.Company || ''
          }`,
        },
      };
    }
  } else if (
    values.userType === UserType.ReferringDoctor &&
    Number(formStep) < 2
  ) {
    if (values.userNewOrExisting === 'new') {
      prefillFields = {
        checkBoxForPhysicianInfo: true,
        physicianFirstName: values?.userFirstName,
        physicianLastName: values?.userLastName,
        physicianEmail: values?.userEmail,
        checkBoxPhysicianPhonePreferred: !!values?.userPhonePreferred,
        checkBoxEmailPreferred: !!values?.userEmailPreferred,
        physicianPhoneNumber: values?.userPhoneNumber,
        physicianPhoneType: values?.userPhoneType,
        physicianPhoneExtension: values?.userPhoneExtension,
      };
    } else {
      let contactText = '';
      if (
        values?.userReferringPhysician?.Phone ||
        values?.userReferringPhysician?.Fax
      ) {
        if (values?.userReferringPhysician?.Phone) {
          contactText = 'ph';
        } else if (values?.userReferringPhysician?.Fax) contactText = 'fax';
      } else {
        contactText = '';
      }

      prefillFields = {
        treatingPhysician: {
          ...values?.userReferringPhysician,
          label: `${values?.userReferringPhysician?.FirstName || ''} ${
            values?.userReferringPhysician?.LastName || ''
          } ${
            values?.userReferringPhysician?.Phone ? '-' : ''
          } ${contactText} ${
            values?.userReferringPhysician?.Phone
              ? values?.userReferringPhysician?.Phone || ''
              : values?.userReferringPhysician?.Fax || ''
          }`,
          id: values?.userReferringPhysician?.SalesforceId,
        },
      };
    }
  } else if (
    values.userType === UserType.Other &&
    Number(formStep) < 4
  ) {
    prefillFields = {
      // customAdjusterInfo: true,
      // customCaseManagerInfo: true,
    };
  }

  return prefillFields;
}

